import { TextColor } from "../util/propsToTextColor";
import { Typography } from "./Typography";

interface Props {
  text?: string;
}

export const ErrorText = ({ text }: Props): JSX.Element | null => {
  return text ? (
    <Typography text={text} textColor={TextColor.DANGER} emphasized />
  ) : null;
};
