import { useTheme } from "styled-components";

export const CupraLogo = (): JSX.Element => {
  const { 500: color } = useTheme().colors.text;

  return (
    <>
      <g clip-path="url(#clip0_1656_98)">
        <path
          d="M9.94812 8.14468C14.5334 9.72842 19.0942 11.3852 23.6902 12.937C23.8528 12.9994 24.0138 12.9994 24.1764 12.937C28.7647 11.3776 33.3224 9.73451 37.9001 8.14468C36.276 10.7152 34.6367 13.2766 32.9774 15.8243C32.2106 15.4969 31.4208 15.2243 30.6479 14.9106C31.0957 14.1278 31.5773 13.3634 32.0312 12.5837C30.5529 13.3223 29.0484 14.0091 27.5808 14.7689C28.6068 15.1573 29.6343 15.538 30.6525 15.9476C31.2199 16.1776 31.8288 16.6207 31.8671 17.2816C31.9576 18.0156 31.453 18.6156 31.0957 19.2034C28.8843 22.6389 26.6852 26.0835 24.4631 29.5129C24.4754 28.6083 24.3711 27.6946 24.4785 26.7962C25.3526 24.0414 26.216 21.2836 27.0119 18.5059C27.1683 18.0933 26.7036 17.8725 26.4046 17.7278C25.5612 17.3577 24.7714 16.8674 23.908 16.5506C23.0323 16.9268 22.192 17.3821 21.3332 17.7948C21.0679 17.9166 20.6937 18.1572 20.8455 18.5014C21.6353 21.2805 22.5079 24.0368 23.3759 26.7931C23.4725 27.6931 23.3805 28.6068 23.3851 29.5129C21.1982 26.114 19.016 22.7105 16.8322 19.31C16.4519 18.6841 15.8998 18.0445 15.9949 17.2633C16.0455 16.607 16.6513 16.1715 17.2187 15.943C18.2277 15.5349 19.2521 15.1618 20.2689 14.7674C18.8043 14.0106 17.3061 13.3177 15.8293 12.5852C16.2817 13.3649 16.7632 14.1278 17.2095 14.9121C16.432 15.2197 15.6452 15.5045 14.8708 15.8197C13.2176 13.269 11.5813 10.7061 9.94812 8.14468Z"
          fill={color}
        />
        <path
          d="M19.6646 39.2468C19.6799 37.7453 19.6539 36.2453 19.6784 34.7438C22.1658 34.7605 24.6548 34.7392 27.1422 34.7529C27.6283 34.7362 28.1957 34.9783 28.2923 35.4991C28.3522 36.0092 28.3384 36.527 28.3015 37.0371C28.2709 37.6006 27.6759 37.9417 27.1514 37.9051C25.1225 37.9097 23.0936 37.9021 21.0632 37.9067C21.0647 38.3574 21.0663 38.8067 21.0724 39.2574C20.6031 39.2696 20.1339 39.2635 19.6646 39.2468ZM21.0586 35.7625C21.0647 36.1128 21.0678 36.463 21.0755 36.8148C23.0016 36.7965 24.9277 36.8316 26.8539 36.7981C27.2035 36.597 26.9658 36.1143 27.0318 35.7915C25.0428 35.7245 23.0492 35.7778 21.0586 35.7625Z"
          fill={color}
        />
        <path
          d="M0.177878 35.4368C0.377239 34.9891 0.894044 34.7393 1.37404 34.75C3.81238 34.7439 6.25072 34.753 8.69059 34.7469C8.69519 35.1612 8.69673 35.5769 8.69979 35.9911C6.31053 36.0078 3.9182 35.9698 1.53047 36.0094C1.48599 36.0444 1.39858 36.116 1.35411 36.1525C1.3265 36.7479 1.36024 37.3464 1.33571 37.9433C1.55194 37.9966 1.77123 38.0515 1.99666 38.0423C4.22951 38.0362 6.46235 38.0454 8.69519 38.0362C8.69673 38.4367 8.69826 38.8372 8.69826 39.2377C6.3596 39.3047 4.01788 39.2469 1.67922 39.2712C1.10567 39.2941 0.394108 39.1844 0.167144 38.5753C0.0429263 38.0362 0.128805 37.4774 0.105802 36.9307C0.113469 36.4342 0.0398592 35.9226 0.177878 35.4368Z"
          fill={color}
        />
        <path
          d="M9.86222 38.1534C9.85762 37.0173 9.85455 35.8813 9.86835 34.7468C10.3683 34.7483 10.8698 34.7453 11.3712 34.7544C11.3881 35.8433 11.3528 36.9336 11.3927 38.0224C13.2483 38.059 15.1054 38.0376 16.961 38.0346C17.0377 36.9412 16.9794 35.8448 16.9963 34.7514C17.5023 34.7483 18.0084 34.7499 18.5145 34.7438C18.5252 35.8783 18.5283 37.0143 18.5176 38.1503C18.5237 38.7595 17.9425 39.2681 17.3429 39.2574C15.6007 39.3092 13.8571 39.2407 12.1135 39.2711C11.6105 39.2696 11.0983 39.3 10.606 39.1767C10.1889 39.0214 9.83922 38.6117 9.86222 38.1534Z"
          fill={color}
        />
        <path
          d="M29.4809 34.7468C31.9239 34.7529 34.3668 34.7468 36.8097 34.7483C37.3373 34.7377 37.9584 35.0103 38.0902 35.5631C38.1271 36.0702 38.1255 36.5818 38.0887 37.0889C38.012 37.5153 37.6333 37.7392 37.2867 37.9371C37.6179 38.3544 37.9124 38.7975 38.2099 39.2391C37.6056 39.2011 36.6886 39.5605 36.3512 38.8813C36.1488 38.5615 35.9571 38.2356 35.7608 37.9128C34.146 37.8991 32.5311 37.9067 30.9163 37.9082C30.9194 38.3498 30.9209 38.7914 30.9255 39.2346C30.4425 39.2772 29.9579 39.2787 29.4763 39.2468C29.484 37.7468 29.4702 36.2468 29.4809 34.7468ZM30.9317 35.7123C30.9194 36.0778 30.9194 36.4448 30.9209 36.8102C32.8287 36.7965 34.7379 36.8361 36.6441 36.792C36.6917 36.7585 36.7867 36.6899 36.8358 36.6549C36.8082 36.3473 36.9938 35.8829 36.6226 35.7306C34.7272 35.691 32.8286 35.729 30.9317 35.7123Z"
          fill={color}
        />
        <path
          d="M39.2864 35.8661C39.2665 35.1793 39.9888 34.7012 40.6298 34.7484C43.0497 34.7469 45.4697 34.753 47.8896 34.7453C47.8973 36.2453 47.9157 37.7468 47.8712 39.2468C47.451 39.2651 47.0262 39.3047 46.6075 39.2529C46.4174 38.8585 46.5784 38.3438 46.5324 37.9067C44.5879 37.9052 42.6433 37.9067 40.6988 37.9052C40.6957 38.3559 40.6988 38.8082 40.7019 39.2605C40.2311 39.2727 39.7618 39.2666 39.2925 39.2468C39.3063 38.1199 39.2634 36.993 39.2864 35.8661ZM40.7049 35.7763C40.6988 36.122 40.7003 36.4677 40.7065 36.8149C42.6449 36.8042 44.5848 36.8118 46.5247 36.8103C46.5278 36.4616 46.5293 36.1144 46.5339 35.7656C44.5909 35.7656 42.6479 35.7458 40.7049 35.7763Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1656_98">
          <rect
            width="48"
            height="31.3701"
            fill={color}
            transform="translate(0 8)"
          />
        </clipPath>
      </defs>
    </>
  );
};
