import { PropsWithChildren, useEffect, useState } from "react";
import { useInterval } from "react-use";
import { Icon, IconType } from "../../../components";
import { Card } from "../../../components/Card";
import { Col } from "../../../components/Col";
import { Spacing } from "../../../components/constraints/Spacing";
import { Row } from "../../../components/Row";
import {
  TextAlign,
  Typography,
  TypographyVariant,
} from "../../../components/Typography";
import {
  useUpdateSmartChargingPolicyMutation,
  useVehicleSmartChargingStatusQuery,
  VehicleSmartChargingStatusQuery,
} from "../../../generated/graphql";
import {
  convertUtcTimeStringToLocalTimeString,
  DetailedPlan,
  getPlanDetails,
} from "../../../util";
import { TextColor } from "../../../util/propsToTextColor";
import { updateSmartChargingStatus } from "../../../util/updateSmartChargingStatus";
import { ChargeState } from "../integration-provider/useEnodeIntegration";
import { SmartChargingPolicy } from "../integration-provider/useEnodeIntegration";
import { SmartChargingConfigurationModal } from "./smart-charging/SmartChargingConfigurationModal";
import { SmartChargingPlanDetails } from "./smart-charging/SmartChargingPlanDetails";
import { SmartChargingPlanGraph } from "./smart-charging/SmartChargingPlanGraph";
import { SmartChargingToggle } from "./smart-charging/SmartChargingToggle";

interface Props {
  smartChargingStatus: string;
  smartChargingPolicy?: SmartChargingPolicy;
  chargeState: ChargeState;
  deadline?: string;
  isSmartChargingCapable: boolean;
  vehicleId: string;

  setSmartChargingStatus: (status: string) => void;
  goToConfigureSmartCharging: () => void;
  goToLocationsOverview: () => void;
  reloadVehicles: () => Promise<void>;
}

export const SmartChargingPolicySection = ({
  smartChargingPolicy,
  smartChargingStatus,

  chargeState,
  deadline,
  isSmartChargingCapable,
  vehicleId,
  setSmartChargingStatus,

  goToConfigureSmartCharging,
  goToLocationsOverview,
  reloadVehicles,
}: PropsWithChildren<Props>): JSX.Element => {
  const { data, refetch } = useVehicleSmartChargingStatusQuery({
    variables: { input: { vehicleId } },
  });
  useInterval(refetch, 10 * 1000);

  const [state, setState] = useState<VehicleSmartChargingStatusQuery>();

  const [planDetails, setPlanDetails] = useState<DetailedPlan>();
  useEffect(() => {
    setState(data);
  }, [data, reloadVehicles]);

  if (!isSmartChargingCapable) {
    return (
      <Card padding={Spacing.MEDIUM}>
        <Typography
          textAlign={TextAlign.CENTER}
          textColor={TextColor.DANGER}
          variant={TypographyVariant.PARAGRAPH}
          text="Denne bilmodellen støtter ikke smartlading"
        />
      </Card>
    );
  }

  useEffect(() => {
    if (state) {
      const status = updateSmartChargingStatus({
        chargeState,
        deadline,
        state,
      });
      setSmartChargingStatus(status);
      if (
        state.vehicleSmartChargingStatus.__typename ===
        "PlannedSmartChargingStatus"
      ) {
        setPlanDetails(getPlanDetails(state.vehicleSmartChargingStatus.plan));
      } else setPlanDetails(undefined);
    }
  }, [state]);

  return (
    <SmartChargingSection
      smartChargingPolicy={smartChargingPolicy}
      smartChargingStatus={smartChargingStatus}
      vehicleId={vehicleId}
      planDetails={planDetails}
      deadlineInLocalTime={
        deadline != null
          ? convertUtcTimeStringToLocalTimeString(deadline)
          : undefined
      }
      goToLocationsOverview={goToLocationsOverview}
      goToConfigureSmartCharging={goToConfigureSmartCharging}
      reloadVehicles={reloadVehicles}
    />
  );
};

interface SmartChargingSectionProps {
  vehicleId: string;
  smartChargingStatus: string;
  smartChargingPolicy?: SmartChargingPolicy;
  planDetails?: DetailedPlan;
  deadlineInLocalTime?: string;
  goToConfigureSmartCharging: () => void;
  goToLocationsOverview: () => void;
  reloadVehicles: () => Promise<void>;
}

const SmartChargingSection = ({
  vehicleId,
  smartChargingPolicy,
  smartChargingStatus,
  planDetails,
  deadlineInLocalTime: deadline,
  goToConfigureSmartCharging,
  goToLocationsOverview,
  reloadVehicles,
}: SmartChargingSectionProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [planDetailsOpen, setPlanDetailsOpen] = useState(false);
  const [smartChargingEnabled, setSmartChargingEnabled] = useState(
    smartChargingPolicy?.isEnabled
  );
  const [error, setError] = useState<string>();

  const [updateSmartChargingPolicyMutation] =
    useUpdateSmartChargingPolicyMutation();

  const toggleSmartCharging = async () => {
    setError(undefined);
    if (!smartChargingPolicy || smartChargingEnabled == null) {
      return;
    }
    try {
      await updateSmartChargingPolicyMutation({
        variables: {
          input: {
            deadlineInUtcTime: smartChargingPolicy.deadlineInUtcTime,
            isEnabled: smartChargingEnabled,
            vehicleId,
          },
        },
      });
      await reloadVehicles();
    } catch (err) {
      setError("Kunne ikke oppdatere, vennligst prøv igjen senere");
      setSmartChargingEnabled(smartChargingPolicy.isEnabled);
    }
  };

  useEffect(() => {
    if (smartChargingEnabled !== smartChargingPolicy?.isEnabled) {
      toggleSmartCharging();
    }
  }, [smartChargingEnabled]);

  useEffect(() => {
    setError(undefined);
    setSmartChargingEnabled(smartChargingPolicy?.isEnabled);
  }, [smartChargingPolicy]);

  return smartChargingPolicy ? (
    <>
      <SmartChargingConfigurationModal
        vehicleId={vehicleId}
        open={modalOpen}
        smartChargingPolicy={smartChargingPolicy}
        goToLocationsOverview={goToLocationsOverview}
        reloadVehicles={reloadVehicles}
        onClose={() => setModalOpen(false)}
      />

      <Card>
        <Row
          style={{
            alignItems: "center",
            display: "grid",
            gap: "1rem",
            gridTemplateColumns: "1fr 32px 32px",
            paddingBottom: Spacing.TINY,
            paddingLeft: Spacing.SMALL,
            paddingRight: Spacing.SMALL,
            paddingTop: Spacing.TINY,
            position: "relative",
          }}
        >
          <Typography
            variant={TypographyVariant.SUB_HEADER}
            textColor={
              smartChargingEnabled ? TextColor.SUCCESS : TextColor.PRIMARY
            }
            text={`Smartlading ${
              smartChargingEnabled
                ? convertUtcTimeStringToLocalTimeString(
                    smartChargingPolicy.deadlineInUtcTime
                  )
                : "av"
            }`}
            emphasized
          />

          <Card
            style={{
              alignItems: "center",
              aspectRatio: "1",
              display: "flex",
              height: 32,
              justifyContent: "center",
            }}
            onClick={() => setModalOpen(!modalOpen)}
          >
            <Icon
              type={IconType.EDIT}
              centerVertically
              style={{
                translate: "2px 1px",
              }}
              size={16}
              color={
                smartChargingEnabled ? TextColor.SUCCESS : TextColor.PRIMARY
              }
            />
          </Card>

          <Card
            style={{
              alignContent: "center",
              aspectRatio: "1",
              display: "flex",
              height: 32,
              justifyContent: "center",
            }}
            onClick={() => setSmartChargingEnabled(!smartChargingEnabled)}
          >
            <SmartChargingToggle
              style={{
                alignItems: "center",
                display: "flex",
              }}
              isEnabled={smartChargingEnabled ?? false}
              onToggle={() => void 0}
            />
          </Card>
        </Row>
        {error && (
          <Typography
            text={error}
            variant={TypographyVariant.PARAGRAPH}
            style={{ fontSize: 14, padding: Spacing.SMALL, paddingTop: 0 }}
            textColor={TextColor.DANGER}
          />
        )}
        <Typography
          variant={TypographyVariant.PARAGRAPH}
          style={{ fontSize: 14, padding: Spacing.SMALL, paddingTop: 0 }}
          text={smartChargingStatus}
        />
        {planDetails && (
          <Col>
            <SmartChargingPlanGraph
              planDetails={planDetails}
              deadline={deadline}
            />

            <SmartChargingPlanDetails
              planDetails={planDetails}
              open={planDetailsOpen}
              setOpen={setPlanDetailsOpen}
            />
          </Col>
        )}
      </Card>
    </>
  ) : (
    <Card
      padding={Spacing.LARGE}
      onClick={goToConfigureSmartCharging}
      style={{
        alignItems: "center",
        alignSelf: "center",
        aspectRatio: "1",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        width: "calc((100% + 5rem)/2)",
      }}
    >
      <Typography
        emphasized
        text="Sett opp smartlading"
        textAlign={TextAlign.CENTER}
        variant={TypographyVariant.HEADER}
        style={{
          translate: "0 -4px",
        }}
      />
    </Card>
  );
};
