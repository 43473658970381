import { Card } from "./Card";
import { Spacing } from "./constraints/Spacing";
import { Icon, IconType } from "./icon";
import { Row } from "./Row";

interface BackHeaderProps {
  goBack: () => void;
  header: JSX.Element | string;
}

export const BackHeader = ({ goBack, header }: BackHeaderProps) => {
  return (
    <Row
      childSpacing={Spacing.TINY}
      style={{
        alignItems: "center",
        display: "grid",
        gridTemplateColumns: "24px 1fr 24px",
        marginTop: "8px",
        width: "100%",
      }}
    >
      <Card
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "0.25rem",
        }}
      >
        <Icon type={IconType.BACK} size={16} onClick={goBack} />
      </Card>

      {header}
    </Row>
  );
};
