import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTheme } from "styled-components";
import { IconType } from "../../../components";
import { Button } from "../../../components/Button";
import { Col } from "../../../components/Col";
import { Spacing } from "../../../components/constraints/Spacing";
import { LinkButton } from "../../../components/LinkButton";
import { HorizontalAlignment } from "../../../components/mixins/HorizontalAlignment";
import { Row } from "../../../components/Row";
import {
  TextAlign,
  Typography,
  TypographyVariant,
} from "../../../components/Typography";
import {
  IntegrationVendor,
  useDisconnectVendorMutation,
  useMyIntegrationsQuery,
} from "../../../generated/graphql";
import { TextColor } from "../../../util/propsToTextColor";
import { IntegrationCard } from "../add/IntegrationCard";

export const DisconnectVendorContainer = (): JSX.Element => {
  const history = useHistory();

  const [vendorToDisconnect, setVendorToDisconnect] =
    useState<IntegrationVendor>();
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [myVendors, setMyVendors] = useState<IntegrationVendor[]>();
  const theme = useTheme();

  const { data } = useMyIntegrationsQuery();

  useEffect(() => {
    if (data) {
      setMyVendors(
        data.me.linkedIntegrationVendors?.map((vendor) => vendor.vendor)
      );
    }
  }, [data]);

  const [disconnectVendor] = useDisconnectVendorMutation();

  const disconnect = async (
    vendor: IntegrationVendor | undefined
  ): Promise<void> => {
    if (vendor === undefined) return;

    setLoading(true);

    const result = await disconnectVendor({
      variables: {
        input: {
          vendor,
        },
      },
    });
    if (result.data?.disconnectVendor.success) {
      history.goBack();
    } else {
      setShowError(true);
      setLoading(false);
    }
  };

  return (
    <Col>
      <Col
        childSpacing={Spacing.MEDIUM}
        horizontalAlignChildren={HorizontalAlignment.CENTER}
      >
        <Typography
          text="Fjern en tilkobling"
          variant={TypographyVariant.HEADER}
        />

        <Typography
          textAlign={TextAlign.CENTER}
          text="Dersom du bekrefter sletting, brytes tilkoblingen til alle kjøretøy fra denne leverandøren, samt eventuelle smartladeinnstillinger og historikk på aktuelle kjøretøy."
        />

        <Row
          style={{
            alignSelf: "stretch",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {myVendors?.map((vendor) => {
            return (
              <IntegrationCard
                style={{ marginLeft: Spacing.TINY, marginTop: Spacing.TINY }}
                marked={vendor === vendorToDisconnect}
                integrationVendor={vendor}
                onClick={() => setVendorToDisconnect(vendor)}
              />
            );
          })}
        </Row>

        {showError && (
          <Typography
            textColor={TextColor.DANGER}
            emphasized
            text="Sletting mislyktes. Prøv igjen."
          />
        )}

        <Button
          iconLeft={{
            color: TextColor.PRIMARY,
            type: IconType.EXCLAMATION,
          }}
          text="Bekreft sletting"
          iconRight={{
            color: TextColor.PRIMARY,
            type: IconType.DELETE,
          }}
          fullWidth
          loading={loading}
          style={{
            background: `linear-gradient(0deg, ${theme.colors.danger[500]}3F 0%, ${theme.colors.danger[500]} 100%)`,
          }}
          onClick={() => disconnect(vendorToDisconnect)}
          disabled={!vendorToDisconnect}
        />

        <LinkButton onClick={() => history.goBack()} text="Avbryt" />
      </Col>
    </Col>
  );
};
