import { useTheme } from "styled-components";

export const VolvoLogo = (): JSX.Element => {
  const { 500: color } = useTheme().colors.text;

  return (
    <>
      <path
        d="M23.026 22.967h-.696v-.458h2.763v.458h-.689v2.067h.638c.591 0 .98-.39.98-.92h.455v1.384H22.33v-.464h.697v-2.067zm3.376 0h-.686v-.458h2.997v.459h-.725L29 24.813l.997-1.845H29.4v-.459h2.065v.459h-.686l-1.37 2.533-1.613-.002-1.394-2.531zm6.91-.521c1.387 0 2.131.692 2.131 1.56 0 .881-.701 1.583-2.124 1.583s-2.132-.702-2.132-1.582c0-.869.736-1.561 2.124-1.561zm-17.604 2.366l.997-1.845h-.596v-.458h2.065v.458h-.686l-1.37 2.533-1.614-.001-1.393-2.532h-.686v-.46h2.997v.46h-.725l1.011 1.845zm4.316-2.366c1.388 0 2.133.692 2.133 1.56 0 .881-.702 1.583-2.125 1.583-1.422 0-2.132-.702-2.132-1.582 0-.869.737-1.561 2.124-1.561zm-.735 1.542c.01-.576.286-1.078.76-1.07.475.008.726.52.716 1.096-.01.544-.234 1.11-.756 1.1-.506-.01-.73-.57-.72-1.126zm13.287 0c.01-.576.286-1.078.76-1.07.475.008.726.52.716 1.096-.01.544-.234 1.11-.755 1.1-.508-.01-.73-.57-.721-1.126zm-20.274-2.883C13.598 15.85 18.343 11.95 24 11.95c5.656 0 10.402 3.899 11.698 9.155H12.302zm23.396 5.79C34.402 32.15 29.657 36.05 24 36.05S13.598 32.15 12.3 26.895h23.397zm1.133-12.455l.758-.758c.539.709 1.024 1.46 1.449 2.25h.598V8.378H32.08v.592c.785.422 1.532.906 2.237 1.441l-.758.758A15.925 15.925 0 0024 8C15.163 8 8 15.163 8 24c0 8.836 7.163 16 16 16 8.836 0 16-7.164 16-16 0-3.584-1.179-6.893-3.17-9.56z"
        fill={color}
      />
    </>
  );
};
