import { DefaultTheme } from "styled-components";
import { ThemeType } from "./ThemeType";

export const DarkTheme: DefaultTheme = {
  colors: {
    background: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "500":
        "linear-gradient(270deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 251, 251, 0.0375) 100%);",
    },

    danger: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "500": "#ffa0b7",
    },

    modalBackground: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "500": "#444444",
    },

    success: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "300": "rgba(64, 255, 198, 0.5)",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "500": "rgba(64, 255, 198, 1)",
    },

    text: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "100": "rgba(255, 255, 255, 0.0375)",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "200": "rgba(255, 255, 255, 0.05)",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "300": "rgba(255, 255, 255, 0.5)",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "500": "#fff",
    },

    warning: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "500": "rgba(255, 243, 165, 1)",
    },
  },
  type: ThemeType.DARK,
};
