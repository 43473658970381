import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util/propsToTextColor";
import { IconProps } from "../Icon";

export const OverviewIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ textColor: color, theme })
    : theme.colors.text[500];

  return (
    <>
      <rect width="4" height="4" fill={fill} />
      <rect y="8" width="4" height="4" fill={fill} />
      <rect x="8" width="4" height="4" fill={fill} />
      <rect x="8" y="8" width="4" height="4" fill={fill} />
    </>
  );
};
