import styled from "styled-components";

interface Props {
  id: string;
  checked: boolean;
  onToggled: () => void;
}

export const Toggle = ({ id, onToggled, checked }: Props): JSX.Element => {
  return (
    <ToggleContainer>
      <CheckBox
        checked={checked}
        onChange={onToggled}
        type="checkbox"
        id={id}
      />
      <CheckBoxLabel htmlFor={id} />
    </ToggleContainer>
  );
};

const width = "24px";
const height = "12px";

const ToggleContainer = styled.div`
  position: relative;
  width: ${width};
  height: ${height};
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: ${width};
  height: ${height};
  border-radius: 16px;
  background: ${(props) => props.theme.colors.text["300"]};
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: ${height};
    height: ${height};
    background: ${(props) => props.theme.colors.text["500"]};
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 16px;
  width: ${width};
  height: ${height};

  &:checked + ${CheckBoxLabel} {
    background: ${(props) => props.theme.colors.success[300]};

    &::after {
      background: ${(props) => props.theme.colors.success[500]};
      margin-left: ${height};
    }
  }
`;
