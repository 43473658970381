import { useTheme } from "styled-components";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ChargingArea = (props: any) => {
  const { x, y, width, height } = props;
  const theme = useTheme();

  return (
    <svg
      width={width}
      height={height}
      x={x}
      y={y}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={width}
        height={height}
        rx="3"
        fill="url(#paint0_linear_1796_6075)"
      />
      <path
        // eslint-disable-next-line no-magic-numbers
        transform={`translate(${(width - 16) / 2}, 2) scale(2)`}
        d="M2 5L4.66667 0V3H6L3.33333 8V5H2Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1796_6075"
          x1={width}
          y1="0"
          x2="0"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme.colors.success[500]} />
          <stop
            offset="1"
            stop-color={theme.colors.success[500]}
            stop-opacity="0.25"
          />
        </linearGradient>
      </defs>
    </svg>
  );
};
