import { CSSProperties } from "react";
import { IntegrationVendor } from "../NoIntegrationsYet";
import { AudiLogo } from "./audi/AudiLogo";
import { BmwLogo } from "./bmw/BmwLogo";
import { CupraLogo } from "./cupra/CupraLogo";
import { JaguarLogo } from "./jaguar/JaguarLogo";
import { MiniLogo } from "./mini/MiniLogo";
import { PorscheLogo } from "./porsche/PorscheLogo";
import { RenaultLogo } from "./renault/RenaultLogo";
import { SeatLogo } from "./seat/SeatLogo";
import { SkodaLogo } from "./skoda/SkodaLogo";
import { TeslaLogo } from "./tesla/TeslaLogo";
import { VolkswagenLogo } from "./volkswagen/VolkswagenLogo";
import { VolvoLogo } from "./volvo/VolvoLogo";

interface Props {
  style?: CSSProperties;
  size?: string;
  integrationVendor: IntegrationVendor;
}

export const IntegrationVendorLogo = ({
  integrationVendor,
  size,
  style,
}: Props): JSX.Element => {
  return (
    <svg
      width={size ?? "48"}
      height={size ?? "48"}
      fill="none"
      style={style}
      viewBox={"0 0 48 48"}
      // viewBox={size ? `0 0 ${size} ${size}` : "0 0 48 48"}
    >
      {integrationVendorToVendorLogo(integrationVendor)}
    </svg>
  );
};

const integrationVendorToVendorLogo = (
  integrationVendor: IntegrationVendor
) => {
  switch (integrationVendor) {
    case "AUDI":
      return <AudiLogo />;

    case "BMW":
      return <BmwLogo />;

    case "CUPRA":
      return <CupraLogo />;

    // case "FORD":
    //   return <FordLogo />;

    case "JAGUAR":
      return <JaguarLogo />;

    case "MINI":
      return <MiniLogo />;

    case "PORSCHE":
      return <PorscheLogo />;

    case "RENAULT":
      return <RenaultLogo />;

    case "SEAT":
      return <SeatLogo />;

    case "SKODA":
      return <SkodaLogo />;

    case "VOLVO":
      return <VolvoLogo />;

    case "TESLA":
      return <TeslaLogo />;

    case "VOLKSWAGEN":
      return <VolkswagenLogo />;
  }
};
