import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util/propsToTextColor";
import { IconProps } from "../Icon";

export const LeftArrowIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ textColor: color, theme })
    : theme.colors.text[500];

  return (
    <>
      <path
        d="M15.375 21.284L6.09099 12L15.375 2.71599L15.375 21.284Z"
        fill={fill}
        stroke={fill}
        stroke-width="2.25"
      />
    </>
  );
};
