import { SmartChargingConsideration } from "../generated/graphql";

interface ConsiderationToSmartChargingStatusProps {
  consideration: SmartChargingConsideration;
  isFullyCharged: boolean;
}

export const considerationToSmartChargingStatus = ({
  consideration: {
    isPluggedIn,
    isCharging,
    hasTimeEstimate,
    recentlyAtChargingLocation,
  },
  isFullyCharged,
}: ConsiderationToSmartChargingStatusProps): SmartChargingConsiderationState => {
  if (!isPluggedIn) {
    return {
      reason:
        "En smartladeplan vil lages når du kobler til kjøretøyet på en av dine ladelokasjoner. Det kan ta et par minutter før ladeplanen er klar.",
      type: "SMART_CHARGING_NOT_ACTIVE",
    };
  }
  if (isFullyCharged) {
    return {
      reason:
        "En ny smartladeplan vil lages når kjøretøyet har behov for lading.",
      type: "SMART_CHARGING_NOT_ACTIVE",
    };
  }
  if (isPluggedIn && !isCharging) {
    return {
      reason:
        "Kjøretøyet trenger å lades noen minutter for å beregne en smartladeplan. Slå på lading for å gjenoppta beregningen.",
      type: "SMART_CHARGING_NOT_ACTIVE",
    };
  }
  if (isPluggedIn && isCharging && !recentlyAtChargingLocation) {
    return {
      reason:
        "En smartladeplan vil lages når du kobler til kjøretøyet på en av dine ladelokasjoner",
      type: "SMART_CHARGING_NOT_ACTIVE",
    };
  }
  if (!recentlyAtChargingLocation) {
    return {
      reason:
        "En smartladeplan vil lages når du kobler til kjøretøyet på en av dine ladelokasjoner. Dersom du nettopp ankom ladelokasjonen din, vennligst gi bilen noen minutter til å oppdatere lokasjonen sin.",
      type: "SMART_CHARGING_NOT_ACTIVE",
    };
  }

  if (!hasTimeEstimate) {
    return {
      reason:
        "Lader nå for å måle ladetempo for smartladeplan. Ladingen settes på pause når planen er klar, og fortsetter i det ideelle tidsvinduet",
      type: "SMART_CHARGING_NOT_ACTIVE",
    };
  }

  return { type: "SMART_CHARGING_ACTIVE" };
};

interface SmartChargingNotActive {
  reason: string;
  type: "SMART_CHARGING_NOT_ACTIVE";
}

interface SmartChargingActive {
  type: "SMART_CHARGING_ACTIVE";
}

type SmartChargingConsiderationState =
  | SmartChargingNotActive
  | SmartChargingActive;
