import { ChargeState } from "../app/integrations/integration-provider/useEnodeIntegration";
import { TextColor } from "./propsToTextColor";

export const chargeStateToColor = (chargeState: ChargeState): TextColor => {
  if (chargeState.isCharging) {
    return TextColor.SUCCESS;
  }

  return TextColor.PRIMARY;
};
