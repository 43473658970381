import { useHistory } from "react-router";
import { Col } from "../../../components/Col";
import { Spacing } from "../../../components/constraints/Spacing";
import { HorizontalAlignment } from "../../../components/mixins/HorizontalAlignment";
import { NavigationButton } from "../../../components/NavigationButton";
import {
  TextAlign,
  Typography,
  TypographyVariant,
} from "../../../components/Typography";
import { IntegrationVendorOverviewGrid } from "../NoIntegrationsYet";

export const AddIntegrationContainer = (): JSX.Element => {
  const history = useHistory();

  return (
    <Col>
      <Col
        childSpacing={Spacing.MEDIUM}
        horizontalAlignChildren={HorizontalAlignment.CENTER}
      >
        <NavigationButton onClick={history.goBack} text="Alle koblinger" />
        <Typography
          text="+ Ny smartkobling"
          variant={TypographyVariant.HEADER}
        />

        <Typography
          textAlign={TextAlign.CENTER}
          text="Velg din leverandør nedenfor for å koble opp."
        />

        <IntegrationVendorOverviewGrid />
      </Col>
    </Col>
  );
};
