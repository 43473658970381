import { Duration } from "luxon";
import { formatTime } from "./formatTime";

/* eslint-disable no-magic-numbers */
export const convertUtcTimeStringToLocalTimeString = (
  utcString: string
): string => {
  const offset = new Date().getTimezoneOffset();

  const duration = Duration.fromObject({ minutes: offset }).toFormat("h m");

  const [utcHour, utcMinute] = utcString.split(":");
  const [hourChange, minuteChange] = duration.split(" ");

  const adjustedHour = parseInt(utcHour) - parseInt(hourChange);
  const adjustedMinute = parseInt(utcMinute) - parseInt(minuteChange);

  const utcAdjustedHourString = formatTime(adjustedHour, "hour");
  const utcAdjustedMinuteString = formatTime(adjustedMinute, "minute");

  return `${utcAdjustedHourString}:${utcAdjustedMinuteString}`;
};
