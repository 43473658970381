import {
  XAxis,
  YAxis,
  ComposedChart,
  Bar,
  Label,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { useTheme } from "styled-components";
import { toFixedIfNecessary } from "../../../util";

export interface ChargingHistoryGraphData {
  date: string;
  kwhSum: number;
}

interface ChargingHistoryGraphProps {
  chargingHistory: ChargingHistoryGraphData[];
}

export const ChargingHistoryGraph = ({
  chargingHistory,
}: ChargingHistoryGraphProps) => {
  const theme = useTheme();
  // The Recharts y-axis width is by default 60. See https://github.com/recharts/recharts/issues/2027 and update when it's auto by default
  const yAxisWidth = 34;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomYAxisTick = (props: any) => {
    const { y, payload, fontSize } = props;

    return (
      <g transform={`translate(${yAxisWidth / 2},${y})`}>
        <text
          x={0}
          y={3}
          textAnchor="middle"
          fill={theme.colors.success[500]}
          fontSize={fontSize}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={410}>
      <ComposedChart
        data={chargingHistory}
        margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
      >
        <XAxis
          dataKey="date"
          scale="band"
          tickFormatter={(tick, index) => (index === 0 ? "" : tick)}
          interval={3}
          fontSize={10}
          tickLine={false}
          axisLine={false}
        />

        <YAxis
          yAxisId="left"
          tickFormatter={(tick) => toFixedIfNecessary(tick)}
          tick={<CustomYAxisTick />}
          tickLine={false}
          axisLine={false}
          width={yAxisWidth}
          fontSize={10}
        >
          <Label
            value="kWh"
            position="bottom"
            fill={theme.colors.success[500]}
            fontSize={10}
          />
        </YAxis>

        <YAxis
          yAxisId="right"
          orientation="right"
          tickLine={false}
          axisLine={false}
          width={yAxisWidth}
        />

        <CartesianGrid
          stroke={theme.colors.modalBackground[500]}
          fill={theme.colors.background[500]}
          vertical={false}
        />

        <Bar dataKey="kwhSum" yAxisId="left" fill={theme.colors.success[500]} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
