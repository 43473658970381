import { CSSProperties, useEffect, useMemo, useState } from "react";
import { Icon, IconType } from "../../../../components";
import { Button } from "../../../../components/Button";
import { Card } from "../../../../components/Card";
import { Col } from "../../../../components/Col";
import { Spacing } from "../../../../components/constraints/Spacing";
import { ErrorText } from "../../../../components/ErrorText";
import { LinkButton } from "../../../../components/LinkButton";
import { Row } from "../../../../components/Row";
import { TextInput } from "../../../../components/TextInput";
import {
  Typography,
  TypographyVariant,
} from "../../../../components/Typography";
import { useDeleteChargingLocationMutation } from "../../../../generated/graphql";
import { TextColor } from "../../../../util/propsToTextColor";
import { ChargingLocation } from "../../integration-provider/useEnodeIntegration";
import { LatLng, LatLngSelection } from "../add/LatLngSelection";

interface Props {
  chargingLocationId?: string;
  initialChargingLocation?: ChargingLocation;
  isSubmittingChargingLocation: boolean;
  submitChargingLocationErrorMessage?: string;
  formTitle: string;
  style?: CSSProperties;
  onCancelClicked: () => void;
  onDeletionComplete?: () => Promise<void>;
  onFormInputSubmitted: (input: ChargingLocationFormInput) => void;
}

export const ChargingLocationForm = ({
  formTitle,
  chargingLocationId,
  initialChargingLocation,
  isSubmittingChargingLocation,
  submitChargingLocationErrorMessage,
  style,
  onCancelClicked,
  onDeletionComplete,
  onFormInputSubmitted,
}: Props): JSX.Element => {
  const [latLng, setLatLng] = useState<LatLng>();
  const [displayName, setDisplayName] = useState("");
  const [latLngError, setLatLngError] = useState<string>();
  const [displayNameError, setDisplayNameError] = useState<string>();

  const initialLatLng: LatLng | undefined = useMemo(() => {
    if (initialChargingLocation) {
      return {
        latitude: initialChargingLocation.latitude,
        longitude: initialChargingLocation.longitude,
      };
    }
  }, [initialChargingLocation]);

  const [isConfirming, setIsConfirming] = useState(false);
  const toggleIsConfirming = () => setIsConfirming(!isConfirming);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const [deleteChargingLocation] = useDeleteChargingLocationMutation();

  const onConfirmClicked = async () => {
    setLoading(true);
    setErrorMessage(undefined);

    if (!chargingLocationId || !onDeletionComplete) return;

    try {
      await deleteChargingLocation({
        variables: { input: { chargingLocationId } },
      });
      await onDeletionComplete();
    } catch (err) {
      setLoading(false);
      setErrorMessage(
        "Kunne ikke slette, prøv igjen og kontakt support om problemet vedvarer"
      );
    }
  };

  useEffect(() => {
    if (initialChargingLocation) {
      setLatLng({
        latitude: initialChargingLocation.latitude,
        longitude: initialChargingLocation.longitude,
      });
      setDisplayName(initialChargingLocation.name);
    } else {
      setLatLng(undefined);
      setDisplayName("");
    }

    setLatLngError(undefined);
    setDisplayNameError(undefined);
  }, [initialChargingLocation]);

  useEffect(() => {
    if (displayName.length > 0) {
      setDisplayNameError(undefined);
    }
  }, [displayName]);

  const validateAndSubmit = async () => {
    if (!latLng) {
      return setLatLngError("Oppgi adresse");
    }

    if (!displayName) {
      return setDisplayNameError("Oppgi visningsnavn");
    }

    onFormInputSubmitted({
      latLng,
      name: displayName,
    });
  };

  return (
    <Col childSpacing={Spacing.SMALL} style={style}>
      {isConfirming ? (
        <>
          <Typography
            text={"Slett ladepunkt"}
            emphasized
            variant={TypographyVariant.HEADER}
            textColor={TextColor.WARNING}
          />

          <Typography
            textColor={TextColor.WARNING}
            text="Følgende lokasjon vil fjernes som gyldig ladepunkt for smart styring:"
          />

          <Card padding={Spacing.SMALL}>
            <Typography text={displayName} emphasized />
          </Card>

          <ErrorText text={errorMessage} />

          <Button
            text="Bekreft sletting"
            textColor={TextColor.WARNING}
            loading={loading}
            iconLeft={{ color: TextColor.WARNING, type: IconType.EXCLAMATION }}
            iconRight={{ color: TextColor.WARNING, type: IconType.DELETE }}
            onClick={onConfirmClicked}
          />

          <LinkButton text="Avbryt" onClick={toggleIsConfirming} />
        </>
      ) : (
        <>
          <Row
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              text={formTitle}
              emphasized
              variant={TypographyVariant.HEADER}
            />

            {chargingLocationId && onDeletionComplete && (
              <Card style={{ padding: "0.25rem" }}>
                <Icon
                  type={IconType.DELETE}
                  style={{ height: "12px" }}
                  onClick={toggleIsConfirming}
                />
              </Card>
            )}
          </Row>

          <LatLngSelection
            onLatLngChanged={setLatLng}
            initialLatLng={initialLatLng}
          />

          <ErrorText text={latLngError} />

          {latLng && (
            <Col>
              <TextInput
                id="displayName"
                label="Visningsnavn"
                value={displayName}
                onChange={setDisplayName}
              />
              <ErrorText text={displayNameError} />
            </Col>
          )}

          <Button
            loading={isSubmittingChargingLocation}
            text="Lagre"
            onClick={validateAndSubmit}
          />

          <ErrorText text={submitChargingLocationErrorMessage} />

          <LinkButton text="Avbryt" onClick={onCancelClicked} />
        </>
      )}
    </Col>
  );
};

export interface ChargingLocationFormInput {
  name: string;
  latLng: LatLng;
}
