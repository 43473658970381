export const rewriteHoursMinutesToDecimalSystem = (
  graphStart: "noon" | "midnight",
  time?: string
): number | undefined => {
  const minutesInHour = 60;
  const hoursInDay = 24;
  const hourlyDisplacenemt = 12;

  if (time == null) return;

  const [hour, minutes] = time.split(":");
  let decimalSystemTime = parseInt(hour);
  if (graphStart === "noon") {
    decimalSystemTime = (decimalSystemTime + hourlyDisplacenemt) % hoursInDay;
  }

  decimalSystemTime += parseInt(minutes) / minutesInHour;
  return decimalSystemTime;
};
