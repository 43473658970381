import { useTheme } from "styled-components";

export const NotReachableIcon = (): JSX.Element => {
  const theme = useTheme();
  const { 500: fill } = theme.colors.text;

  return (
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00015 7.85445C8.00015 7.36266 7.60148 6.96399 7.1097 6.96399C6.61791 6.96399 6.21924 7.36266 6.21924 7.85445C6.21924 8.34623 6.61791 8.7449 7.1097 8.7449C7.60148 8.7449 8.00015 8.34623 8.00015 7.85445Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.59438 1.02454C5.308 1.10588 5.026 1.2039 4.74985 1.31829C3.81354 1.70612 2.96279 2.27457 2.24617 2.99119C1.52956 3.7078 0.961103 4.55855 0.573272 5.49486C0.247054 6.28242 0.0540006 7.11754 0.000895169 7.96612C-0.015743 8.23199 0.201775 8.44814 0.468159 8.44814C0.734543 8.44814 0.948698 8.23192 0.967709 7.96622C1.01935 7.24441 1.18678 6.53452 1.4645 5.86402C1.80386 5.04475 2.30125 4.30035 2.92829 3.67331C3.55533 3.04627 4.29974 2.54887 5.11901 2.20952C5.2365 2.16085 5.35521 2.11557 5.47498 2.0737V1.64002C5.47498 1.42235 5.51738 1.21459 5.59438 1.02454Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.47473 4.20513V3.1062C4.77769 3.39694 4.14424 3.8213 3.61014 4.3554C3.07268 4.89287 2.64634 5.53093 2.35547 6.23316C2.1282 6.78182 1.98711 7.36125 1.93627 7.95097C1.91266 8.22486 2.13774 8.44812 2.41265 8.44812C2.68756 8.44812 2.90774 8.22467 2.93624 7.95125C2.98407 7.49239 3.09801 7.04196 3.27522 6.61413C3.51606 6.03269 3.86907 5.50437 4.31409 5.05935C4.65629 4.71715 5.04774 4.42936 5.47473 4.20513Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.52475 5.26306C5.32807 5.39757 5.1438 5.55033 4.9745 5.71963C4.61619 6.07794 4.33197 6.50331 4.13805 6.97147C4.00581 7.29072 3.91734 7.6256 3.87443 7.96703C3.84122 8.23133 4.06027 8.44811 4.32666 8.44811C4.59304 8.44811 4.8049 8.2307 4.84909 7.968C4.88522 7.75326 4.94555 7.54276 5.02928 7.34063C5.17472 6.98951 5.38789 6.67048 5.65662 6.40175C5.77166 6.28671 5.89591 6.18185 6.02796 6.08813C5.78523 5.87316 5.6067 5.58734 5.52475 5.26306Z"
        fill={fill}
      />
      <path
        d="M7.99973 1.63499V4.855C7.99973 5.34653 7.60126 5.745 7.10973 5.745C6.61819 5.745 6.21973 5.34653 6.21973 4.855V1.63499C6.21973 1.14346 6.61819 0.744995 7.10973 0.744995C7.60126 0.744995 7.99973 1.14346 7.99973 1.63499Z"
        fill={fill}
      />
    </>
  );
};
