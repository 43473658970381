import styled, { DefaultTheme } from "styled-components";

interface Props {
  batteryChargeState: BatteryChargeState;
  progress: number;
  batteryPadding: string;
  gradient?: boolean;
  height?: string;
  width?: string;
}

export enum BatteryChargeState {
  CHARGING = "CHARGING",
  NOT_CHARGING = "NOT_CHARGING",
  NOT_CHARGING_LOW_BATTERY = "NOT_CHARGING_LOW_BATTERY",
}

export const BatteryProgressBar = ({
  batteryChargeState: chargeState,
  height,
  width,
  batteryPadding,
  progress,
  gradient,
}: Props): JSX.Element => {
  return (
    <Container height={height} width={width} padding={batteryPadding}>
      <Progress
        progress={progress}
        batteryChargeState={chargeState}
        gradient={gradient}
      />
    </Container>
  );
};

const Container = styled.div<{
  height?: string;
  width?: string;
  padding: string;
}>`
  position: relative;
  width: ${(props) => props.width ?? "100%"};
  height: ${(props) => props.height ?? "16px"};
  padding: ${(props) => props.padding};

  background: ${(props) => `${props.theme.colors.text[100]}`};
  border-radius: 4px;
`;

const Progress = styled.div<{
  progress: number;
  batteryChargeState: BatteryChargeState;
  gradient?: boolean;
}>`
  width: ${({ progress }) => progress}%;
  height: 100%;
  background: ${({ batteryChargeState, theme, gradient }) =>
    batteryChargeStateToColor({ batteryChargeState, gradient, theme })};
  border-radius: 2px 1px 1px 2px;
`;

const batteryChargeStateToColor = ({
  batteryChargeState,
  gradient,
  theme,
}: {
  batteryChargeState: BatteryChargeState;
  gradient?: boolean;
  theme: DefaultTheme;
}): string => {
  switch (batteryChargeState) {
    case BatteryChargeState.CHARGING:
      return gradient
        ? `linear-gradient(270deg, ${theme.colors.success[500]} 0%, ${theme.colors.modalBackground[500]}1A 100%)`
        : theme.colors.success["500"];
    case BatteryChargeState.NOT_CHARGING_LOW_BATTERY:
      return `linear-gradient(90deg, ${theme.colors.danger["500"]} 0%, ${theme.colors.warning["500"]} 100%)`;
    default:
      return gradient
        ? `linear-gradient(270deg, ${theme.colors.text[500]} 0%, ${theme.colors.modalBackground[500]}1A 100%)`
        : theme.colors.text["500"];
  }
};
