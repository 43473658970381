import { createContext, PropsWithChildren, useContext } from "react";
import { Loader } from "../../../components/Loader";
import { Typography } from "../../../components/Typography";
import { NoIntegrationsYet } from "../NoIntegrationsYet";
import { EnodeIntegrationDown } from "./EnodeIntegrationDown";
import {
  ChargingLocation,
  ConfiguredIntegrationVendorVehicle,
  EnodeIntegrationStateKey,
  useEnodeIntegration,
} from "./useEnodeIntegration";

interface IntegrationProviderContext {
  chargingLocations: ChargingLocation[];
  vehicles: ConfiguredIntegrationVendorVehicle[];
  reload: () => Promise<void>;
}
const IntegrationProviderContext = createContext<
  IntegrationProviderContext | undefined
>(undefined);

export const IntegrationProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const enodeIntegration = useEnodeIntegration();

  const reload = async () => {
    await enodeIntegration.refetch();
  };

  if (enodeIntegration.state.loading) {
    return <Loader size="FULLSCREEN" />;
  }

  if (enodeIntegration.state.value) {
    if (
      enodeIntegration.state.value.key ===
      EnodeIntegrationStateKey.NOT_AUTHENTICATED
    ) {
      return <NoIntegrationsYet />;
    }

    if (
      enodeIntegration.state.value.key ===
      EnodeIntegrationStateKey.UNKNOWN_ERROR
    ) {
      return (
        <EnodeIntegrationDown
          isReloadingIntegrations={enodeIntegration.state.loading}
          reload={reload}
        />
      );
    }

    return (
      <IntegrationProviderContext.Provider
        value={{
          chargingLocations: enodeIntegration.state.value.chargingLocations,
          reload,
          vehicles: enodeIntegration.state.value.vehicles,
        }}
      >
        {children}
      </IntegrationProviderContext.Provider>
    );
  }

  return <Typography text="Noe gikk galt" />;
};

export const useIntegrations = (): IntegrationProviderContext => {
  const context = useContext(IntegrationProviderContext);

  if (!context) {
    throw new Error(
      `useIntegrations must be called inside an IntegrationProvider`
    );
  }

  return context;
};
