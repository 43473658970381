import { DateTime } from "luxon";
import { SmartChargingPlan } from "../generated/graphql";
import { convertUtcTimeStringToLocalTimeString } from "./convertUtcTimeStringToLocalTimeString";
import { stringToSimpleDateTimeFormat } from "./stringToSimpleDateTimeFormat";

export interface DetailedPlan {
  pauseAt?: string;
  resumeAt?: string;
  finishAt: string;
  chargeTime?: string;
  savings?: string;
}

export const getPlanDetails = (plan: SmartChargingPlan) => {
  const chargetime = plan.startAt
    ? DateTime.fromISO(plan.endedAt ? plan.endedAt : plan.estimatedFinishAt)
        .diff(DateTime.fromISO(plan.startAt), ["hours", "minutes", "seconds"])
        .toObject()
    : undefined;

  const detailedPlan: DetailedPlan = {
    chargeTime:
      chargetime && chargetime.hours
        ? `${chargetime.hours}t ${chargetime.minutes}min`
        : undefined,
    finishAt: convertUtcTimeStringToLocalTimeString(
      stringToSimpleDateTimeFormat(
        plan.endedAt ? plan.endedAt : plan.estimatedFinishAt
      )
    ),
    pauseAt: plan.stopConfirmedAt
      ? convertUtcTimeStringToLocalTimeString(
          stringToSimpleDateTimeFormat(plan.stopConfirmedAt)
        )
      : undefined,
    resumeAt: plan.startConfirmedAt
      ? convertUtcTimeStringToLocalTimeString(
          stringToSimpleDateTimeFormat(plan.startConfirmedAt)
        )
      : plan.startAt
      ? convertUtcTimeStringToLocalTimeString(
          stringToSimpleDateTimeFormat(plan.startAt)
        )
      : undefined,
    savings: plan.smartCost
      ? `${(plan.nonSmartCost - plan.smartCost).toFixed(2)} kr`
      : undefined,
  };
  return detailedPlan;
};
