import { Icon, IconType } from "../../components";
import { ButtonIconProps } from "../../components/Button";
import { Card } from "../../components/Card";
import { VerticalAlignment } from "../../components/mixins/VerticalAlignment";
import { Row } from "../../components/Row";
import {
  TextAlign,
  Typography,
  TypographyVariant,
} from "../../components/Typography";

interface CardButtonProps {
  text: string;
  iconLeft?: ButtonIconProps | JSX.Element;
  iconRight?: ButtonIconProps | JSX.Element;
  onClick: () => void;
}

export const CardButton = ({
  text,
  iconLeft,
  iconRight,
  onClick,
}: CardButtonProps): JSX.Element => {
  return (
    <Card
      style={{
        borderRadius: "4px",
        padding: "8px 16px",
      }}
      onClick={onClick}
    >
      <Row
        verticalAlignChildren={VerticalAlignment.CENTER}
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {iconLeft && iconLeft.type in IconType ? (
          <Icon type={iconLeft.type} />
        ) : (
          iconLeft
        )}

        <Typography
          variant={TypographyVariant.DETAIL}
          textAlign={TextAlign.CENTER}
          centerVertically
          text={text}
          style={{
            fontSize: "14",
            fontWeight: "900",
            lineHeight: "1rem",
          }}
        />

        {iconRight && iconRight.type in IconType ? (
          <Icon type={iconRight.type} />
        ) : (
          iconRight
        )}
      </Row>
    </Card>
  );
};
