import { useEffect, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Col } from "../../../../components/Col";
import { Spacing } from "../../../../components/constraints/Spacing";
import { ErrorText } from "../../../../components/ErrorText";
import { Loader } from "../../../../components/Loader";
import { Row } from "../../../../components/Row";
import { Typography } from "../../../../components/Typography";

interface Props {
  onLatLngChanged: (latLng: LatLng) => void;
  initialLatLng?: LatLng;
}

export const LatLngSelection = ({
  onLatLngChanged,
  initialLatLng,
}: Props): JSX.Element => {
  const [place, setPlace] = useState<PlaceResult | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isFetchingLatLng, setIsFetchingLatLng] = useState(!!initialLatLng);

  const updatePlace = (place: PlaceResult): void => {
    setPlace(place);
  };

  useEffect(() => {
    const initPlaceForLatLng = async () => {
      if (initialLatLng) {
        const result = await geocodeByLatLng({
          lat: initialLatLng.latitude,
          lng: initialLatLng.longitude,
        });
        const [firstResult] = result;

        setPlace({
          label: firstResult.formatted_address,
          value: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            place_id: firstResult.place_id,
            types: firstResult.types,
          },
        });
        result[0].formatted_address;
      } else {
        setPlace(null);
      }
    };

    initPlaceForLatLng();
  }, [initialLatLng]);

  useEffect(() => {
    const updateLatLngForPlace = async () => {
      if (!place) {
        return;
      }
      setErrorMessage(undefined);

      setIsFetchingLatLng(true);

      try {
        const result = await geocodeByPlaceId(place.value.place_id);

        const latitude = result[0].geometry.location.lat();
        const longitude = result[0].geometry.location.lng();

        onLatLngChanged({ latitude, longitude });
        setIsFetchingLatLng(false);
      } catch (err) {
        setIsFetchingLatLng(false);
        setErrorMessage(
          "Kunne ikke finne lokasjon for adresse, prøv igjen eller oppgi en annen adresse"
        );
      }
    };

    updateLatLngForPlace();
  }, [place]);

  return (
    <Col childSpacing={Spacing.TINY}>
      {isFetchingLatLng ? (
        <Row childSpacing={Spacing.SMALL} style={{ justifyContent: "center" }}>
          <Loader />
          <Typography text="Henter posisjon" />
        </Row>
      ) : (
        <GooglePlacesAutocomplete
          minLengthAutocomplete={5}
          selectProps={{
            onChange: updatePlace,
            placeholder: "Adresse med gatenummer…",
            value: place,
          }}
          autocompletionRequest={{ types: ["address"] }}
        />
      )}
      <ErrorText text={errorMessage} />
    </Col>
  );
};

interface PlaceResult {
  label: string;
  value: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    place_id: string;
    types: string[];
  };
}

export interface LatLng {
  latitude: number;
  longitude: number;
}
