import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util/propsToTextColor";
import { IconProps } from "../Icon";

export const ExclamationIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ textColor: color, theme })
    : theme.colors.warning[500];

  return (
    <>
      <path d="M9.5 13H6.5V16H9.5V13Z" fill={fill} />
      <path d="M9.5 0H6.5V10H9.5V0Z" fill={fill} />
    </>
  );
};
