import { CSSProperties, useTheme } from "styled-components";
import { Spacing } from "../../../components/constraints/Spacing";
import { Row } from "../../../components/Row";
import {
  TextAlign,
  Typography,
  TypographyVariant,
} from "../../../components/Typography";
import { ConfiguredIntegrationVendorVehicle } from "../../../generated/graphql";
import { IntegrationVendorLogo } from "./IntegrationVendorLogo";

interface Props {
  vehicle: ConfiguredIntegrationVendorVehicle;
  logoSize?: string;
  fullWidth?: boolean;
  noBackground?: boolean;
  style?: CSSProperties;
}

export const IntegrationVendorComponent = ({
  vehicle,
  logoSize,
  fullWidth = false,
  noBackground = false,
  style,
}: Props): JSX.Element => {
  const theme = useTheme();
  return (
    <Row
      childSpacing={Spacing.TINY}
      style={{
        background: !noBackground ? theme.colors.background[500] : undefined,
        borderRadius: "4px",
        justifyContent: "center",
        padding: "0.25rem",
        width: fullWidth ? "100%" : "max-content",
        ...style,
      }}
    >
      <IntegrationVendorLogo
        integrationVendor={vehicle.vendor}
        size={logoSize ?? "24px"}
      />
      <Typography
        emphasized
        variant={TypographyVariant.PARAGRAPH}
        textAlign={TextAlign.CENTER}
        text={`${vehicle.vendor} ${vehicle.model}`}
        centerVertically
      />
    </Row>
  );
};
