import { CSSProperties, useTheme } from "styled-components";
import { Card } from "../../../components/Card";
import { IntegrationVendor } from "../NoIntegrationsYet";
import { IntegrationVendorLogo } from "../vendors/IntegrationVendorLogo";

interface Props {
  onClick: () => void;
  marked?: boolean;
  integrationVendor: IntegrationVendor;
  style?: CSSProperties;
}

export const IntegrationCard = ({
  integrationVendor,
  marked,
  onClick,
  style: customStyle,
}: Props): JSX.Element => {
  const theme = useTheme();
  let style = customStyle;

  if (marked) {
    style = {
      background: `linear-gradient(0deg, ${theme.colors.danger[500]}3F 0%, ${theme.colors.danger[500]} 100%)`,
      ...customStyle,
    };
  }

  return (
    <Card
      onClick={onClick}
      style={{
        alignItems: "center",
        display: "flex",
        height: "4.5rem",
        justifyContent: "center",
        width: "4.5rem",
        ...style,
      }}
    >
      <IntegrationVendorLogo integrationVendor={integrationVendor} />
    </Card>
  );
};
