import { useTheme } from "styled-components";

export const AudiLogo = (): JSX.Element => {
  const { 500: color } = useTheme().colors.text;

  return (
    <>
      <path
        d="M8.327 16.98a7.347 7.347 0 10.49 0h-.49zm10.285 0a7.347 7.347 0 10.49 0h-.49zm10.286 0a7.347 7.347 0 10.49 0h-.49zm10.286 0a7.347 7.347 0 10.49 0h-.49z"
        stroke={color}
        strokeWidth={2}
      />
    </>
  );
};
