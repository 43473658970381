import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util/propsToTextColor";
import { IconProps } from "../Icon";

export const BackIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ textColor: color, theme })
    : theme.colors.text[500];

  return (
    <>
      <path
        d="M4.81655 3L0.5 8L4.81655 13V9.33094L14.4928 9.29496L15.5 6.66906L4.81655 6.70504V3Z"
        fill={fill}
      />
    </>
  );
};
