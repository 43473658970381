import { Card } from "../../components/Card";
import { Col } from "../../components/Col";
import { Spacing } from "../../components/constraints/Spacing";
import { Row } from "../../components/Row";
import { Typography, TypographyVariant } from "../../components/Typography";
import {
  chargeStateToBatteryChargeState,
  chargeStateToColor,
} from "../../util";
import { BatteryProgressBar } from "./BatteryProgressBar";
import { ChargingStatusCard } from "./ChargingStatusCard";
import { ConfiguredIntegrationVendorVehicle } from "./integration-provider/useEnodeIntegration";
import { IntegrationVendorComponent } from "./vendors/IntegrationVendorComponent";

interface Props {
  vehicle: ConfiguredIntegrationVendorVehicle;
  onClick: () => void;
}

export const ConfiguredEnodeVendorVehicleListEntry = ({
  vehicle,
  onClick,
}: Props): JSX.Element => {
  return (
    <Card padding={Spacing.SMALL} key={vehicle.id} onClick={onClick}>
      <Col
        style={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
        }}
        childSpacing={Spacing.TINY}
      >
        <IntegrationVendorComponent
          vehicle={vehicle}
          fullWidth
          noBackground
          logoSize="24px"
        />

        <BatteryProgressBar
          height="16px"
          width="calc(100% - 64px)"
          batteryPadding="4px"
          batteryChargeState={chargeStateToBatteryChargeState(
            vehicle.chargeState
          )}
          progress={vehicle.chargeState.batteryLevel}
        />

        <Row childSpacing={Spacing.TINY} style={{ justifyContent: "center" }}>
          <Card
            padding={Spacing.TINY}
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography
              style={{ alignSelf: "center" }}
              textColor={chargeStateToColor(vehicle.chargeState)}
              variant={TypographyVariant.NUMBER_SMALL}
              emphasized
              text={`${vehicle.chargeState.batteryLevel}%`}
            />
          </Card>

          <ChargingStatusCard vehicle={vehicle} />
        </Row>
      </Col>
    </Card>
  );
};
