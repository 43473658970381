import { useHistory } from "react-router";
import { Icon, IconType } from "../../../../components";
import { Card } from "../../../../components/Card";
import { Col } from "../../../../components/Col";
import { Spacing } from "../../../../components/constraints/Spacing";
import { LinkButton } from "../../../../components/LinkButton";
import { Row } from "../../../../components/Row";
import {
  Typography,
  TypographyVariant,
} from "../../../../components/Typography";
import { RouteMap } from "../../../Routes";
import { useIntegrations } from "../../integration-provider/IntegrationProvider";
import { AddButton } from "../../IntegrationsContainer";

export const ChargingLocationsOverviewContainer = (): JSX.Element => {
  const { chargingLocations } = useIntegrations();

  const history = useHistory();

  const add = () => {
    history.push(RouteMap.integrations.chargingLocations.add);
  };

  const edit = (locationId: string) => {
    history.push(
      RouteMap.integrations.chargingLocations.update.createPathForLocationId(
        locationId
      )
    );
  };

  return (
    <Col childSpacing={Spacing.SMALL}>
      <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
        <Typography
          emphasized
          variant={TypographyVariant.HEADER}
          text="Dine lokasjoner"
        />

        <AddButton onClick={add} />
      </Row>

      <Col childSpacing={Spacing.TINY} style={{ flexGrow: 1 }}>
        {chargingLocations.map((location) => {
          return (
            <Card
              key={location.id}
              padding={Spacing.SMALL}
              onClick={() => edit(location.id)}
            >
              <Row
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography text={location.name} />
                <Icon type={IconType.SETTINGS} />
              </Row>
            </Card>
          );
        })}
      </Col>
      <LinkButton text="Tilbake" onClick={history.goBack} />
    </Col>
  );
};
