import { BatteryChargeState } from "../app/integrations/BatteryProgressBar";
import { ChargeState } from "../app/integrations/integration-provider/useEnodeIntegration";

const LOW_BATTERY_LEVEL = 20;

export const chargeStateToBatteryChargeState = (
  chargeState: ChargeState
): BatteryChargeState => {
  if (chargeState.isCharging) {
    return BatteryChargeState.CHARGING;
  }
  if (chargeState.batteryLevel < LOW_BATTERY_LEVEL) {
    return BatteryChargeState.NOT_CHARGING_LOW_BATTERY;
  }

  return BatteryChargeState.NOT_CHARGING;
};
