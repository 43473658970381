import { CSSProperties, PropsWithChildren } from "react";
import styled from "styled-components";
import { Spacing } from "./constraints/Spacing";
import { mixins } from "./mixins";
import { HorizontalAlignment } from "./mixins/HorizontalAlignment";
import { Padding } from "./mixins/Padding";
import { VerticalAlignment } from "./mixins/VerticalAlignment";

interface Props extends StyledColProps {
  style?: CSSProperties;
  onClick?: () => void;
}

export const Col = ({
  children,
  style,
  onClick,
  ...styledColProps
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <StyledCol style={style} onClick={onClick} {...styledColProps}>
      {children}
    </StyledCol>
  );
};

interface StyledColProps {
  padding?: Padding;
  childSpacing?: Spacing;
  marginTop?: Spacing;
  horizontalAlignChildren?: HorizontalAlignment;
  verticalAlignChildren?: VerticalAlignment;
}

const StyledCol = styled.div<StyledColProps>`
  display: flex;
  flex-direction: column;

  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}

  ${({ horizontalAlignChildren }) =>
    horizontalAlignmentToCss(horizontalAlignChildren)}
  ${({ verticalAlignChildren }) =>
    verticalAlignmentToCss(verticalAlignChildren)}

  ${(props) => mixins.padding(props.padding)}
  ${(props) => mixins.verticalChildSpacing(props.childSpacing)}
`;

const horizontalAlignmentToCss = (
  horizontalAlignChildren?: HorizontalAlignment
) => {
  switch (horizontalAlignChildren) {
    case HorizontalAlignment.CENTER:
      return "align-items: center;";
  }
};

const verticalAlignmentToCss = (alignment?: VerticalAlignment) => {
  switch (alignment) {
    case VerticalAlignment.CENTER:
      return "justify-content: center;";
  }
};
