import { ChangeEvent } from "react";
import styled from "styled-components";
import { ThemeType } from "../theme/ThemeType";

interface TimeInputProps {
  value: string;
  onValueChanged: (newValue: string) => void;
}
export const TimeInput = ({ value, onValueChanged }: TimeInputProps) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    onValueChanged(value);
  };

  return (
    <StyledTimeInput
      min="00:00"
      max="23:59"
      onChange={onChange}
      value={value}
    />
  );
};

const StyledTimeInput = styled.input.attrs({ type: "time" })`
  border: 0;
  font-size: 1rem;
  line-height: 1rem;
  width: 100%;
  color: ${(props) => props.theme.colors.text[500]};
  font-family: Source Sans Pro;
  font-weight: 900;

  background: ${(props) => props.theme.colors.background[500]};
  border-radius: 4px;
  padding: 8px 16px;

  // Needed to make the clock icon white for dark themes in chrome based browsers
  ${(props) =>
    props.theme.type === ThemeType.DARK &&
    `
      ::-webkit-calendar-picker-indicator {
          filter: invert(1);
        }
    `};
`;
