import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util/propsToTextColor";
import { IconProps } from "../Icon";

export const ConnectionIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ textColor: color, theme })
    : theme.colors.danger[500];

  return (
    <>
      <path
        d="M4.32714 14.8813C4.24198 15.2158 3.97018 15.5 3.625 15.5C3.27982 15.5 3.00802 15.2158 2.92286 14.8813C2.69791 13.9977 2.00229 13.3021 1.11868 13.0771C0.784167 12.992 0.5 12.7202 0.5 12.375C0.5 12.0298 0.784168 11.758 1.11868 11.6729C2.00229 11.4479 2.69791 10.7523 2.92286 9.86868C3.00802 9.53417 3.27982 9.25 3.625 9.25C3.97018 9.25 4.24198 9.53417 4.32714 9.86868C4.55209 10.7523 5.24771 11.4479 6.13132 11.6729C6.46583 11.758 6.75 12.0298 6.75 12.375C6.75 12.7202 6.46583 12.992 6.13132 13.0771C5.24771 13.3021 4.55209 13.9977 4.32714 14.8813Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.32714 6.13132C4.24198 6.46583 3.97018 6.75 3.625 6.75C3.27982 6.75 3.00802 6.46583 2.92286 6.13132C2.69791 5.24771 2.00229 4.55209 1.11868 4.32714C0.784167 4.24198 0.5 3.97018 0.5 3.625C0.5 3.27982 0.784168 3.00802 1.11868 2.92286C2.00229 2.69791 2.69791 2.00229 2.92286 1.11868C3.00802 0.784167 3.27982 0.5 3.625 0.5C3.97018 0.5 4.24198 0.784167 4.32714 1.11868C4.60246 2.20015 5.58282 3 6.75 3H9.25C10.4172 3 11.3975 2.20015 11.6729 1.11868C11.758 0.784167 12.0298 0.5 12.375 0.5C12.7202 0.5 12.992 0.784167 13.0771 1.11868C13.3021 2.00229 13.9977 2.69791 14.8813 2.92286C15.2158 3.00802 15.5 3.27982 15.5 3.625C15.5 3.97018 15.2158 4.24198 14.8813 4.32714C13.7999 4.60246 13 5.58282 13 6.75L13 9.25C13 10.4172 13.7999 11.3975 14.8813 11.6729C15.2158 11.758 15.5 12.0298 15.5 12.375C15.5 12.7202 15.2158 12.992 14.8813 13.0771C13.9977 13.3021 13.3021 13.9977 13.0771 14.8813C12.992 15.2158 12.7202 15.5 12.375 15.5C12.0298 15.5 11.758 15.2158 11.6729 14.8813C11.4479 13.9977 10.7523 13.3021 9.86868 13.0771C9.53417 12.992 9.25 12.7202 9.25 12.375C9.25 12.0298 9.53417 11.758 9.86868 11.6729C10.9495 11.3977 11.749 10.4184 11.75 9.2522L11.75 9.25V6.75L11.75 6.74779C11.7488 5.36937 10.632 4.25207 9.25382 4.25H6.74618C5.58068 4.25175 4.60216 5.05103 4.32714 6.13132Z"
        fill={fill}
      />
    </>
  );
};
