import { Duration } from "luxon";
import { formatTime } from "./formatTime";

/* eslint-disable no-magic-numbers */
export const convertLocalTimeStringToUtcTimeString = (
  timeString: string
): string => {
  const offset = new Date().getTimezoneOffset();

  const duration = Duration.fromObject({ minutes: offset }).toFormat("h m");

  const [hourString, minuteString] = timeString.split(":");
  const [hourChange, minuteChange] = duration.split(" ");

  const utcAdjustedHour = parseInt(hourString) + parseInt(hourChange);
  const utcAdjustedMinute = parseInt(minuteString) + parseInt(minuteChange);

  const utcAdjustedHourString = formatTime(utcAdjustedHour, "hour");
  const utcAdjustedMinuteString = formatTime(utcAdjustedMinute, "minute");

  return `${utcAdjustedHourString}:${utcAdjustedMinuteString}`;
};
