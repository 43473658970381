import { useState } from "react";
import { Button } from "../../../../components/Button";
import { Col } from "../../../../components/Col";
import { Spacing } from "../../../../components/constraints/Spacing";
import { ErrorText } from "../../../../components/ErrorText";
import { LinkButton } from "../../../../components/LinkButton";
import { Modal } from "../../../../components/modal";
import { Row } from "../../../../components/Row";
import { IconType, TimeInput } from "../../../../components";
import {
  Typography,
  TypographyVariant,
} from "../../../../components/Typography";
import {
  SmartChargingPolicy,
  useUpdateSmartChargingPolicyMutation,
} from "../../../../generated/graphql";
import {
  convertLocalTimeStringToUtcTimeString,
  convertUtcTimeStringToLocalTimeString,
} from "../../../../util";
import { SmartChargingToggle } from "./SmartChargingToggle";
import { useIntegrations } from "../../integration-provider/IntegrationProvider";
import { PositionIcon } from "../../../../components/icon/positionIcons/PositionIcon";
import { TextColor } from "../../../../util/propsToTextColor";
import { CardButton } from "../../CardButton";

interface SmartChargingConfigurationModalProps {
  vehicleId: string;
  open: boolean;
  smartChargingPolicy: SmartChargingPolicy;
  reloadVehicles: () => Promise<void>;
  goToLocationsOverview: () => void;
  onClose: () => void;
}

export const SmartChargingConfigurationModal = ({
  vehicleId,
  open,
  smartChargingPolicy,
  goToLocationsOverview,
  reloadVehicles,
  onClose: onCloseModal,
}: SmartChargingConfigurationModalProps): JSX.Element => {
  const { chargingLocations } = useIntegrations();
  const [smartChargingEnabled, setSmartChargingEnabled] = useState(
    smartChargingPolicy.isEnabled
  );

  const existingChargeDeadline = smartChargingPolicy.deadlineInUtcTime;
  const [timeString, setTimeString] = useState(
    existingChargeDeadline
      ? convertUtcTimeStringToLocalTimeString(existingChargeDeadline)
      : "08:00"
  );

  const [updateSmartChargingPolicyMutation] =
    useUpdateSmartChargingPolicyMutation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const submitSmartCharging = async () => {
    setLoading(true);

    const utcAdjustedTimeString =
      convertLocalTimeStringToUtcTimeString(timeString);

    try {
      setErrorMessage(undefined);

      await updateSmartChargingPolicyMutation({
        variables: {
          input: {
            deadlineInUtcTime: utcAdjustedTimeString,
            isEnabled: smartChargingEnabled,
            vehicleId,
          },
        },
      });
      await reloadVehicles();
      setLoading(false);
      onClose();
    } catch (err) {
      setLoading(false);
      setErrorMessage("Kunne ikke oppdatere");
    }
  };

  const onClose = () => {
    setSmartChargingEnabled(smartChargingPolicy.isEnabled);
    setTimeString(
      existingChargeDeadline
        ? convertUtcTimeStringToLocalTimeString(existingChargeDeadline)
        : "08:00"
    );
    onCloseModal();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Col childSpacing={Spacing.SMALL}>
        <Row
          style={{
            alignContent: "center",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            variant={TypographyVariant.SUB_HEADER}
            textColor={
              smartChargingPolicy.isEnabled
                ? TextColor.SUCCESS
                : TextColor.PRIMARY
            }
            text="Smartlading"
            centerVertically
            emphasized
          />

          <SmartChargingToggle
            style={{
              alignItems: "center",
              display: "flex",
            }}
            isEnabled={smartChargingEnabled}
            onToggle={() => setSmartChargingEnabled(!smartChargingEnabled)}
          />
        </Row>

        <TimeInput value={timeString} onValueChanged={setTimeString} />

        <CardButton
          text={locationCountToLabelString(chargingLocations.length)}
          iconLeft={<PositionIcon hasPosition={chargingLocations.length > 0} />}
          iconRight={{ type: IconType.CONTINUE }}
          onClick={goToLocationsOverview}
        />

        <Row
          style={{
            alignContent: "center",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <LinkButton
            text="Avbryt"
            onClick={onClose}
            textColor={TextColor.DANGER}
          />

          <Button
            loading={loading}
            textColor={TextColor.SUCCESS}
            text="Lagre"
            onClick={submitSmartCharging}
          />
        </Row>

        <ErrorText text={errorMessage} />
      </Col>
    </Modal>
  );
};

const locationCountToLabelString = (locationCount: number): string => {
  switch (locationCount) {
    case 1:
      return "1 lokasjon";
    default:
      return `${locationCount} lokasjoner`;
  }
};
