import {
  ChildSpacing,
  horizontalChildSpacingToCss,
  verticalChildSpacingToCss,
} from "./ChildSpacing";
import { Padding, paddingToCss } from "./Padding";

export const mixins = {
  horizontalChildSpacing: (childSpacing?: ChildSpacing): string | undefined =>
    childSpacing && horizontalChildSpacingToCss(childSpacing),
  padding: (padding?: Padding): string | undefined =>
    padding && paddingToCss(padding),
  verticalChildSpacing: (childSpacing?: ChildSpacing): string | undefined =>
    childSpacing && verticalChildSpacingToCss(childSpacing),
};
