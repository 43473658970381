import { Icon, IconType } from "../../../../components";
import { Card } from "../../../../components/Card";
import { Col } from "../../../../components/Col";
import { Spacing } from "../../../../components/constraints/Spacing";
import { Row } from "../../../../components/Row";
import {
  Typography,
  TypographyVariant,
} from "../../../../components/Typography";
import { DetailedPlan } from "../../../../util";

interface SmartChargingPlanDetailsProps {
  planDetails: DetailedPlan;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const SmartChargingPlanDetails = ({
  planDetails,
  open,
  setOpen,
}: SmartChargingPlanDetailsProps) => {
  return (
    <>
      {open ? (
        <>
          <Col
            childSpacing={Spacing.TINY}
            style={{
              padding: Spacing.SMALL,
              paddingTop: Spacing.NONE,
            }}
          >
            {planDetails.pauseAt && (
              <SmartChargingPlanDetailsRow
                header="Lading satt på pause"
                content={planDetails.pauseAt}
              />
            )}

            {planDetails.resumeAt && (
              <SmartChargingPlanDetailsRow
                header="Lading fortsetter"
                content={planDetails.resumeAt}
              />
            )}

            <SmartChargingPlanDetailsRow
              header="Lading fullføres"
              content={planDetails.finishAt}
            />

            {planDetails.chargeTime && (
              <SmartChargingPlanDetailsRow
                header="Estimert ladetid"
                content={planDetails.chargeTime}
                faded
              />
            )}
          </Col>

          <Card
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "center",
              padding: Spacing.TINY,
            }}
            onClick={() => setOpen(false)}
          >
            <Typography
              text="Vis mindre"
              emphasized
              variant={TypographyVariant.SUB_HEADER}
            />

            <Icon size={8} centerVertically type={IconType.COLLAPSE} />
          </Card>
        </>
      ) : (
        <Card
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            padding: Spacing.TINY,
          }}
          onClick={() => setOpen(true)}
        >
          <Typography
            text="Detaljer"
            variant={TypographyVariant.SUB_HEADER}
            emphasized
          />

          <Icon size={8} centerVertically type={IconType.EXPAND} />
        </Card>
      )}
    </>
  );
};

interface SmartChargingPlanDetailsRowProps {
  header: string;
  content: string;
  faded?: boolean;
}

const SmartChargingPlanDetailsRow = ({
  header,
  content,
  faded = false,
}: SmartChargingPlanDetailsRowProps) => {
  return (
    // eslint-disable-next-line no-magic-numbers
    <Row style={{ justifyContent: "space-between", opacity: faded ? 0.75 : 1 }}>
      <Typography style={{ fontSize: 12 }} text={header} />

      <Typography style={{ fontSize: 12 }} text={content} emphasized />
    </Row>
  );
};
