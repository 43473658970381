import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { BackHeader, Icon, IconType } from "../../../components";
import { Card } from "../../../components/Card";
import { Col } from "../../../components/Col";
import { Spacing } from "../../../components/constraints/Spacing";
import { HorizontalAlignment } from "../../../components/mixins/HorizontalAlignment";
import { Row } from "../../../components/Row";
import { Typography, TypographyVariant } from "../../../components/Typography";
import { useIntegrations } from "../integration-provider/IntegrationProvider";
import { VehicleOverviewContainerProps } from "../vehicle-overview/VehicleOverviewContainer";
import { IntegrationVendorComponent } from "../vendors/IntegrationVendorComponent";

import { Loader } from "../../../components/Loader";
import { useChargingStatisticsQuery } from "../../../generated/graphql";
import {
  fromSimpleDateFormat,
  getHourString,
  toSimpleDateFormat,
  toFixedIfNecessary,
} from "../../../util";
import {
  ChargingHistoryGraph,
  ChargingHistoryGraphData,
} from "./ChargingHistoryGraph";
import { TextColor } from "../../../util/propsToTextColor";
import { SummaryCard } from "./SummaryCard";

export const ChargingHistoryContainer = (): JSX.Element => {
  const history = useHistory();

  const { vehicleId } = useParams<VehicleOverviewContainerProps>();

  const { vehicles } = useIntegrations();

  const currentVehicle = vehicles.find((vehicle) => vehicle.id === vehicleId);
  if (!currentVehicle) {
    return <Typography text={`Kobling med id ${vehicleId} ikke funnet.`} />;
  }

  const [date, setDate] = useState<string>(makeDateString());
  const [disableAddOneDay, setDisableAddOneDay] = useState<boolean>(
    makeDateString() === date
  );

  const [chargingHistory, setChargingHistory] = useState<
    ChargingHistoryGraphData[]
  >([]);

  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();

  const {
    data: chargingStatisticsData,
    loading: chargingStatisticsQueryLoading,
    error: chargingStatisticsQueryError,
  } = useChargingStatisticsQuery({
    variables: {
      input: {
        endDate: fromSimpleDateFormat(date)
          .plus({ days: 1 })
          .minus({ minutes: 1 })
          .toString(),
        id: vehicleId,
        startDate: fromSimpleDateFormat(date).toString(),
      },
    },
  });

  useEffect(() => {
    setDisableAddOneDay(makeDateString() === date);
  }, [date]);

  const subtractOneDay = () => {
    const initialDate = fromSimpleDateFormat(date);
    setDate(makeDateString(initialDate.minus({ days: 1 })));
  };

  const addOneDay = () => {
    if (disableAddOneDay) {
      return;
    }

    const initialDate = fromSimpleDateFormat(date);
    setDate(makeDateString(initialDate.plus({ days: 1 })));
  };

  function makeDateString(date?: DateTime): string {
    if (date) {
      return toSimpleDateFormat(date).toString();
    }
    return toSimpleDateFormat(DateTime.now()).toString();
  }

  useEffect(() => {
    setLoading(true);
    if (chargingStatisticsData) {
      setChargingHistory(
        chargingStatisticsData.chargingStatistics.entries.map((entry) => ({
          date: getHourString(entry.date),
          kwhSum: entry.kwhSum,
        }))
      );
      setInitialLoading(false);
      setLoading(false);
    }
    if (chargingStatisticsQueryError) {
      setError(error);
      setInitialLoading(false);
      setLoading(false);
    }
  }, [chargingStatisticsData, error, chargingStatisticsQueryError]);

  useEffect(() => {
    setLoading(chargingStatisticsQueryLoading);
  }, [chargingStatisticsQueryLoading]);

  const kwhSum = chargingStatisticsData?.chargingStatistics.entries
    .map((entry) => entry.kwhSum)
    .reduce((acc, cum) => acc + cum, 0);

  const chargingHours = chargingStatisticsData?.chargingStatistics.entries
    .map((entry) => entry.kwhSum)
    .reduce((acc, cum) => (cum !== 0 ? acc + 1 : acc), 0);

  const hasSmartCharged = chargingStatisticsData?.chargingStatistics.entries
    .flatMap((entry) => entry.smartSession)
    .includes(true);

  return (
    <Col>
      <Col
        childSpacing={Spacing.MEDIUM}
        horizontalAlignChildren={HorizontalAlignment.CENTER}
      >
        <Col
          childSpacing={Spacing.SMALL}
          horizontalAlignChildren={HorizontalAlignment.CENTER}
          style={{ width: "100%" }}
        >
          <BackHeader
            goBack={() => history.goBack()}
            header={
              <Typography
                text="Ladehistorikk"
                variant={TypographyVariant.HEADER}
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              />
            }
          />

          <IntegrationVendorComponent vehicle={currentVehicle} noBackground />
        </Col>

        <Row childSpacing={Spacing.SMALL}>
          <Icon
            type={IconType.LEFT_ARROW}
            size={24}
            centerVertically
            onClick={subtractOneDay}
          />

          <Card padding={Spacing.SMALL}>
            <Typography
              text={date}
              variant={TypographyVariant.SUB_HEADER}
              emphasized
              style={{ translate: "0 -2.5px" }}
            />
          </Card>

          <Icon
            type={IconType.RIGHT_ARROW}
            size={24}
            centerVertically
            onClick={addOneDay}
            // eslint-disable-next-line no-magic-numbers
            style={{ opacity: disableAddOneDay ? 0.5 : 1 }}
          />
        </Row>

        {initialLoading ? (
          <Loader size="FULLSCREEN" />
        ) : error ? (
          <Typography text={error} />
        ) : (
          <ChargingHistoryGraph chargingHistory={chargingHistory} />
        )}

        <Row
          childSpacing={Spacing.TINY}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            width: "100%",
          }}
        >
          <SummaryCard
            title="Lademengde"
            loading={loading}
            content={
              <Typography
                text={(kwhSum ? toFixedIfNecessary(kwhSum, 1) : 0) + " kWh"}
                style={{
                  fontSize: "14px",
                }}
                emphasized
              />
            }
          />

          <SummaryCard
            title="Ladetid"
            loading={loading}
            content={
              <Typography
                text={`${chargingHours} ${
                  chargingHours === 1 ? "time" : "timer"
                }`}
                style={{
                  fontSize: "14px",
                }}
                emphasized
              />
            }
          />

          <SummaryCard
            title="Smartlading"
            loading={loading}
            color={hasSmartCharged ? TextColor.SUCCESS : TextColor.PRIMARY}
            content={
              <Row horizontalAlignChildren={HorizontalAlignment.CENTER}>
                {hasSmartCharged && <Icon type={IconType.CHECK} />}

                <Typography
                  text={hasSmartCharged ? "Aktiv" : "Inaktiv"}
                  style={{
                    fontSize: "14px",
                  }}
                  textColor={
                    hasSmartCharged ? TextColor.SUCCESS : TextColor.PRIMARY
                  }
                  emphasized
                />
              </Row>
            }
          />
        </Row>
      </Col>
    </Col>
  );
};
