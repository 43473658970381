import { useTheme } from "styled-components";

export const MiniLogo = (): JSX.Element => {
  const { 500: color } = useTheme().colors.text;

  return (
    <>
      <path
        d="M0 17.966h.235c4.897 0 9.794 0 14.692.003.137 0 .213-.042.286-.154a10.653 10.653 0 014.179-3.734 10.2 10.2 0 012.93-.942 10.351 10.351 0 015.772.699c1.39.595 2.592 1.46 3.608 2.57.405.443.747.944 1.106 1.427.075.1.142.137.264.137 4.902-.003 9.805-.003 14.708-.003H48c-.05.06-.078.096-.11.129-.423.43-.848.858-1.267 1.292a.337.337 0 01-.27.109c-4.142-.003-8.283-.002-12.425-.002h-.194l.57 1.79h10.43c-.053.06-.085.1-.12.136-.414.423-.83.844-1.241 1.271a.37.37 0 01-.295.127c-2.777-.003-5.555-.003-8.332-.003h-.207v1.786h6.932c-.064.072-.1.117-.14.156-.41.414-.82.825-1.225 1.243a.358.358 0 01-.283.119c-1.778-.004-3.555-.003-5.332-.003h-.188l-.572 1.792h4.478c-.057.062-.091.102-.128.139-.415.422-.833.842-1.245 1.268a.354.354 0 01-.282.12c-1.153-.004-2.305 0-3.457-.005-.14-.001-.225.034-.304.156a10.53 10.53 0 01-2.236 2.484 10.404 10.404 0 01-4.363 2.092 10.26 10.26 0 01-3.287.173 10.163 10.163 0 01-3.771-1.152c-1.531-.822-2.8-1.94-3.768-3.384-.182-.272-.36-.384-.696-.376-1.075.026-2.152.009-3.228.012a.328.328 0 01-.254-.107c-.465-.456-.937-.906-1.406-1.358l.039-.056h4.46c-.192-.6-.377-1.185-.572-1.799h-.16c-1.788 0-3.577-.001-5.365.003a.34.34 0 01-.27-.112c-.423-.437-.852-.869-1.278-1.303-.03-.03-.057-.062-.104-.116h6.944v-1.772h-.197c-2.783 0-5.565 0-8.348.002-.118 0-.2-.03-.284-.116-.41-.427-.826-.848-1.24-1.272-.038-.037-.073-.077-.131-.14h10.431l.57-1.795h-.182c-4.142 0-8.284 0-12.425.003a.374.374 0 01-.296-.126C.93 18.912.48 18.456 0 17.966zM23.709 32.88c1.33-.002 2.33-.165 3.295-.517a8.992 8.992 0 004.375-3.358 9.046 9.046 0 001.592-4.165 9.102 9.102 0 00-.117-3.012c-.431-2.021-1.404-3.738-2.983-5.088-2.234-1.907-4.812-2.579-7.683-2.017-1.975.386-3.642 1.377-4.946 2.911-2.003 2.357-2.688 5.076-2.045 8.1.427 2.007 1.453 3.683 3.021 5.011 1.666 1.41 3.6 2.118 5.49 2.135z"
        fill={color}
      />
      <path
        d="M21.974 26.113h-.94v-3.476L21 22.631c-.077.244-.156.488-.232.733-.273.877-.546 1.753-.816 2.63-.028.09-.064.132-.166.128-.25-.008-.5-.01-.75.001-.132.007-.187-.038-.226-.162-.332-1.069-.672-2.135-1.008-3.202a.248.248 0 00-.072-.124v3.472h-.927v-4.82c.261 0 .52-.002.778 0 .2.003.449-.06.585.036.127.09.134.35.193.535l.972 3.078c.016.05.035.099.063.181.03-.077.051-.123.066-.172.363-1.16.727-2.321 1.082-3.484.043-.14.105-.182.25-.178.39.012.78.004 1.183.004v4.826zM29.135 26.12c-.368 0-.726.005-1.084-.007-.049 0-.111-.072-.14-.125-.572-1.048-1.14-2.097-1.708-3.146-.028-.05-.06-.099-.112-.189v3.453h-.944v-4.818c.368 0 .735-.005 1.102.006.043.001.097.074.125.126.577 1.062 1.152 2.126 1.728 3.19l.095.173v-3.487h.939v4.823zM23.087 21.294h.962v4.814h-.962v-4.814zM30.239 21.294h.965v4.814h-.965v-4.814z"
        fill={color}
      />
    </>
  );
};
