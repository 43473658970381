import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util/propsToTextColor";
import { IconProps } from "../Icon";

export const DeleteIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ textColor: color, theme })
    : theme.colors.danger[500];

  return (
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.5 0.5C9.5 0.223858 9.27614 0 9 0H7C6.72386 0 6.5 0.223858 6.5 0.5V1.5C6.5 1.77614 6.27614 2 6 2H2C1.72386 2 1.5 2.22386 1.5 2.5V3.5C1.5 3.77614 1.72386 4 2 4H14C14.2761 4 14.5 3.77614 14.5 3.5V2.5C14.5 2.22386 14.2761 2 14 2H10C9.72386 2 9.5 1.77614 9.5 1.5V0.5Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5 5H8.5L8.5 13.5C8.5 13.7761 8.27614 14 8 14C7.72386 14 7.5 13.7761 7.5 13.5L7.5 5H5.5L5.5 13.5C5.5 13.7761 5.27614 14 5 14C4.72386 14 4.5 13.7761 4.5 13.5L4.5 5H2.5V13C2.5 14.6569 3.84315 16 5.5 16H10.5C12.1569 16 13.5 14.6569 13.5 13V5H11.5V13.5C11.5 13.7761 11.2761 14 11 14C10.7239 14 10.5 13.7761 10.5 13.5L10.5 5Z"
        fill={fill}
      />
    </>
  );
};
