import styled from "styled-components";
import { mixins } from "./mixins";
import { ChildSpacing } from "./mixins/ChildSpacing";
import { HorizontalAlignment } from "./mixins/HorizontalAlignment";
import { VerticalAlignment } from "./mixins/VerticalAlignment";

export interface Props {
  childSpacing?: ChildSpacing;
  horizontalAlignChildren?: HorizontalAlignment;
  verticalAlignChildren?: VerticalAlignment;
}

export const Row = styled.div<Props>`
  display: flex;
  flex-direction: row;

  ${({ horizontalAlignChildren }) =>
    horizontalAlignmentToCss(horizontalAlignChildren)}

  ${({ verticalAlignChildren }) =>
    verticalAlignmentToCss(verticalAlignChildren)}

  ${(props) => mixins.horizontalChildSpacing(props.childSpacing)}
`;

const horizontalAlignmentToCss = (
  horizontalAlignChildren?: HorizontalAlignment
) => {
  switch (horizontalAlignChildren) {
    case HorizontalAlignment.CENTER:
      return "justify-content: center;";
  }
};

const verticalAlignmentToCss = (verticalAlignment?: VerticalAlignment) => {
  switch (verticalAlignment) {
    case VerticalAlignment.CENTER:
      return "align-items: center;";
  }
};
