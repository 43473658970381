import { useTheme } from "styled-components";

export const RenaultLogo = (): JSX.Element => {
  const { 500: color } = useTheme().colors.text;

  return (
    <>
      <path
        d="M20.484 8.13c-2.805 5.251-5.613 10.5-8.421 15.75l-.063.157a.23.23 0 01.061.08c2.815 5.263 5.63 10.527 8.443 15.79.037.07.077.093.154.092.747-.002 1.495-.002 2.243 0 .073 0 .113-.019.148-.086.458-.86.918-1.72 1.378-2.58l.13-.243.812-1.518 6.14-11.474c.04-.073.04-.125 0-.198-1.8-3.358-3.598-6.718-5.397-10.077-.016-.03-.034-.058-.057-.096l-.057.098c-.373.698-.745 1.396-1.12 2.092-.035.066-.034.114.002.18 1.397 2.609 2.791 5.219 4.187 7.828.028.053.04.094.007.155-2.416 4.513-4.83 9.027-7.244 13.54-.014.027-.03.053-.052.089l-.057-.097-.69-1.292-6.546-12.242c-.03-.055-.032-.098-.002-.155a9510.59 9510.59 0 003.37-6.298l5.092-9.522c.017-.03.03-.06.05-.1h-.117c-.725 0-1.451.003-2.177-.003-.11 0-.165.033-.217.13z"
        fill={color}
      />
      <path
        d="M26.667 10.3c-.022.037-.039.062-.053.089l-6.898 12.884c-.113.21-.222.421-.34.628-.04.07-.035.122.002.19 1.262 2.357 2.522 4.715 3.782 7.072.136.255.274.508.407.765.018.033.025.088.01.119-.388.732-.78 1.463-1.17 2.194-.006.01-.015.02-.027.035l-.444-.826c-1.668-3.123-3.336-6.246-5.006-9.368-.032-.06-.034-.103-.001-.163 2.817-5.263 5.632-10.527 8.445-15.792.05-.093.102-.128.21-.127.725.006 1.451.005 2.177 0 .095 0 .143.028.188.113 1.976 3.7 3.955 7.399 5.933 11.097.842 1.574 1.683 3.147 2.526 4.72.026.05.028.086 0 .138-2.57 4.804-5.14 9.608-7.708 14.413l-.772 1.446c-.024.045-.047.073-.106.073-.774-.002-1.547-.002-2.32-.002-.013 0-.025-.002-.05-.005.017-.035.03-.066.046-.095a124135.452 124135.452 0 018.462-15.821c.03-.056.029-.1-.001-.155l-7.236-13.524-.056-.098z"
        fill={color}
      />
    </>
  );
};
