export enum IconType {
  BACK = "BACK",
  CHECK = "CHECK",
  COLLAPSE = "COLLAPSE",
  CONNECTION = "CONNECTION",
  CONTINUE = "CONTINUE",
  DELETE = "DELETE",
  EDIT = "EDIT",
  EXCLAMATION = "EXCLAMATION",
  EXPAND = "EXPAND",
  LEFT_ARROW = "LEFT_ARROW",
  OVERVIEW = "OVERVIEW",
  PLUS = "PLUS",
  RIGHT_ARROW = "RIGHT_ARROW",
  SETTINGS = "SETTINGS",
}
