import { DefaultTheme } from "styled-components";

export enum TextColor {
  PRIMARY = "PRIMARY",
  SUCCESS = "SUCCESS",
  DANGER = "DANGER",
  WARNING = "WARNING",
}

export interface VariantProps {
  textColor: TextColor;
  theme: DefaultTheme;
}

export const propsToTextColor = (props: VariantProps) => {
  switch (props.textColor) {
    case TextColor.PRIMARY:
      return props.theme.colors.text[500];
    case TextColor.DANGER:
      return props.theme.colors.danger[500];
    case TextColor.SUCCESS:
      return props.theme.colors.success[500];
    case TextColor.WARNING:
      return props.theme.colors.warning[500];
  }
};
