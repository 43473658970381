import { useTheme } from "styled-components";

export const SeatLogo = (): JSX.Element => {
  const { 500: color } = useTheme().colors.text;

  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.14 23.824c-.35-.026-.412-.142-.412-.343 0-.184.106-.29.372-.29h5.23v-.787h-5.362c-.766 0-1.287.28-1.287 1.093 0 .818.394 1.093 1.264 1.161l4.242.338c.328.027.416.132.416.353 0 .212-.1.344-.388.344h-5.323v.796h5.417c.932 0 1.342-.354 1.342-1.192 0-.765-.372-1.066-1.28-1.14l-4.232-.333zm8.117 1.093c0 .57.122.776.787.776h4.737v.796h-5.003c-1.136 0-1.664-.485-1.664-1.403v-2.682h6.667v.798h-5.524v.802h5.301v.78h-5.3v.133zm15.267-2.513v.798h2.667v3.287h1.136v-3.287H48v-.798h-6.476zm-6.328 0c-1.137 0-1.658.575-1.658 1.494v2.591h1.137v-1.192h4.32v1.192h1.137v-4.085h-4.936zm3.799.798v1.292h-4.32v-.359c0-.559.094-.933.787-.933h3.533zM0 22.994c.01-.396.034-1.365.053-1.937.024-.73.173-1.176.464-1.444.403-.37.872-.458 1.992-.52 2.596-.147 5.24-.127 8.551.113.345.025.475.092.543.14.146.105.165.221.204.83.022.348.067 1.277.092 1.787-1.143-.06-3.081-.164-4.77-.181-1.69-.018-3.21.01-3.39.034-.37.049-.539.227-.589.306l8.765 1.682v1.324L0 22.994zM0 23.425l11.915 2.335c-.008.34-.035 1.422-.055 2.042-.024.743-.173 1.196-.464 1.468-.402.376-.872.466-1.992.53-2.596.148-5.238.128-8.55-.116-.344-.025-.474-.094-.542-.143-.147-.105-.166-.224-.204-.842-.027-.414-.083-1.635-.102-2.04 1.536.11 3.896.19 5.82.19.483 0 1.739.016 2.073.016.261 0 .613-.076.79-.368L0 24.937v-1.512z"
        fill={color}
      />
    </>
  );
};
