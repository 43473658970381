import { DateTime } from "luxon";

export const getHourString = (date: string | DateTime) => {
  let hour: string;
  if (typeof date === "string") {
    hour = DateTime.fromISO(date).hour.toString();
  } else {
    hour = date.hour.toString();
  }
  return hour.length === 1 ? `0${hour}` : hour;
};
