import { useTheme } from "styled-components";

export const TeslaLogo = (): JSX.Element => {
  const { 500: color } = useTheme().colors.text;

  return (
    <>
      <path
        d="M24.003 40l4.602-25.384c4.386 0 5.77.471 5.969 2.397 0 0 2.942-1.076 4.426-3.262-5.79-2.632-11.61-2.75-11.61-2.75l-3.394 4.056h.008L20.607 11S14.79 11.119 9 13.75c1.483 2.186 4.426 3.262 4.426 3.262.2-1.925 1.583-2.397 5.94-2.4L24.002 40z"
        fill={color}
      />
      <path
        d="M24 9.95c4.554-.035 9.767.71 15.103 3.05.714-1.292.897-1.863.897-1.863C34.167 8.815 28.704 8.02 24 8c-4.705.02-10.167.815-16 3.137 0 0 .26.704.896 1.863 5.335-2.34 10.549-3.085 15.103-3.05H24"
        fill={color}
      />
    </>
  );
};
