interface SmartChargingPlanGraphData {
  hour: string;
  count: number;
}

export const makeSmartChargingPlanGraphData = (
  startAt: number
): SmartChargingPlanGraphData[] => {
  const data: SmartChargingPlanGraphData[] = [];
  let count = 0;

  // eslint-disable-next-line no-magic-numbers
  while (count <= 24) {
    // eslint-disable-next-line no-magic-numbers
    const hour = ((startAt + count) % 24).toString();

    data.push({
      count,
      hour: hour.length > 1 ? hour : `0${hour}`,
    });
    count += 1;
  }

  return data;
};
