import { useEffect, useState } from "react";
import { LatLng } from "../app/integrations/charging-locations/add/LatLngSelection";

export const getLatLngAddress = (location: LatLng): string => {
  const [place, setPlace] = useState<string>("Ukjent lokasjon");

  const geocoder = new google.maps.Geocoder();

  useEffect(() => {
    const initPlaceForLatLng = async () => {
      const result = await geocoder.geocode({
        location: {
          lat: location.latitude,
          lng: location.longitude,
        },
      });

      const formattedAddress = result.results.filter(
        (loc) => loc.types[0] !== "plus_code"
      )[0]?.formatted_address;

      setPlace(
        formattedAddress
          ? `I nærheten av ${formattedAddress}`
          : "Ukjent lokasjon"
      );
    };

    initPlaceForLatLng();
  }, [location]);

  return place;
};
