import {
  SmartChargingPlan,
  SmartChargingPlanningStatusType,
} from "../generated/graphql";
import { convertUtcTimeStringToLocalTimeString } from "./convertUtcTimeStringToLocalTimeString";
import { stringToSimpleDateTimeFormat } from "./stringToSimpleDateTimeFormat";

export const planningToSmartChargingStatus = (
  plannedStatus: SmartChargingPlanningStatusType,
  plan: SmartChargingPlan,
  deadline?: string
): string => {
  const startAt = plan.startAt
    ? convertUtcTimeStringToLocalTimeString(
        stringToSimpleDateTimeFormat(plan?.startAt)
      )
    : undefined;
  const estimatedFinishAt = convertUtcTimeStringToLocalTimeString(
    stringToSimpleDateTimeFormat(plan.estimatedFinishAt)
  );

  switch (plannedStatus) {
    case "PLAN_EXECUTING_STOPPING":
    case "PLAN_EXECUTING_STOPPED":
      return startAt
        ? `Lading vil fortsette ${startAt} og ferdigstilles rundt ${estimatedFinishAt}`
        : `Ladingen ferdigstilles rundt ${estimatedFinishAt}`;
    case "PLAN_EXECUTING_STOPPED_AWAITING_PRICES":
      return `Avventer morgendagens strømpriser før en smartladeplan kan utformes. Ladingen vil fullføres før ${deadline}`;
    case "PLAN_EXECUTING_STARTING":
    case "PLAN_EXECUTING_STARTED":
      return `Ladingen ferdigstilles rundt ${estimatedFinishAt}`;
    default:
      // TODO: add Logger from util or LogRocket (if it's GDPR friendly)
      console.log("Fallbacking to default planning status...");
      return "Smartlading planlagt";
  }
};
