import { useTheme } from "styled-components";

export const NoPositionIcon = (): JSX.Element => {
  const theme = useTheme();
  const { 500: fill } = theme.colors.text;

  return (
    <>
      <path
        opacity="0.75"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.67709 1.3842C-0.324188 2.72778 -0.214952 4.63769 1.0048 5.85771L1.0048 5.85777V5.85783L2.94594 7.79896C3.21396 8.06698 3.6485 8.06698 3.91652 7.79896L5.50419 6.21129L3.93243 4.63953C3.42737 4.82833 2.8364 4.71966 2.43025 4.31351C2.0241 3.90736 1.91543 3.31639 2.10423 2.81133L0.67709 1.3842ZM4.68663 3.97952C4.97439 3.44218 4.89163 2.75869 4.43835 2.30541C3.98507 1.85213 3.30158 1.76937 2.76424 2.05713L1.3842 0.67709C2.72788 -0.324262 4.63798 -0.214935 5.85799 1.00507C7.07799 2.22508 7.18732 4.13517 6.18597 5.47886L4.68663 3.97952Z"
        fill={fill}
      />
    </>
  );
};
