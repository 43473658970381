import { CSSProperties } from "react";
import { Toggle } from "../../../../components/Toggle";

interface SmartChargingToggleProps {
  isEnabled: boolean;
  onToggle: () => void;
  style?: CSSProperties;
}

export const SmartChargingToggle = ({
  isEnabled,
  onToggle,
  style,
}: SmartChargingToggleProps) => {
  return (
    <div style={style}>
      <Toggle
        id="smart-charging-enabled"
        onToggled={onToggle}
        checked={isEnabled}
      />
    </div>
  );
};
