import { CSSProperties } from "react";
import { useTheme } from "styled-components";
import { Button } from "../../../components/Button";
import { Card } from "../../../components/Card";
import { Col } from "../../../components/Col";
import { Spacing } from "../../../components/constraints/Spacing";
import {
  TextAlign,
  Typography,
  TypographyVariant,
} from "../../../components/Typography";

interface Props {
  reload: () => void;
  isReloadingIntegrations: boolean;
}

export const EnodeIntegrationDown = ({
  reload,
  isReloadingIntegrations,
}: Props): JSX.Element => {
  return (
    <Col childSpacing={Spacing.SMALL}>
      <Typography
        textAlign={TextAlign.CENTER}
        text="Smartkoblinger"
        variant={TypographyVariant.HEADER}
        emphasized
      />
      <Card padding={{ horizontal: Spacing.SMALL, vertical: Spacing.MEDIUM }}>
        <Col childSpacing={Spacing.MEDIUM}>
          <ContentMissing style={{ alignSelf: "center" }} />
          <Typography
            textAlign={TextAlign.CENTER}
            text="Kunne ikke laste innhold."
          />
          <Button
            text="Prøv igjen"
            onClick={reload}
            loading={isReloadingIntegrations}
          />
        </Col>
      </Card>
    </Col>
  );
};

interface ContentMissingProps {
  style?: CSSProperties;
}

const ContentMissing = ({ style }: ContentMissingProps) => {
  const theme = useTheme();
  const { 500: fill } = theme.colors.text;

  return (
    <svg
      width={224}
      height={80}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <rect width={192} height={16} rx={8} fill={fill} fillOpacity={0.1} />
      <rect
        y={64}
        width={192}
        height={16}
        rx={8}
        fill={fill}
        fillOpacity={0.1}
      />
      <rect
        y={32}
        width={192}
        height={16}
        rx={8}
        fill={fill}
        fillOpacity={0.1}
      />
      <rect
        x={208}
        y={64}
        width={16}
        height={16}
        rx={8}
        fill={fill}
        fillOpacity={0.1}
      />
      <rect
        x={208}
        width={16}
        height={48}
        rx={8}
        fill={fill}
        fillOpacity={0.1}
      />
    </svg>
  );
};
