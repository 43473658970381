import { Route, Switch } from "react-router-dom";
import { AddIntegrationContainer } from "./integrations/add/AddIntegrationContainer";
import { ChargingHistoryContainer } from "./integrations/charging-history/ChargingHistoryContainer";
import { AddChargingLocationContainer } from "./integrations/charging-locations/add/AddChargingLocationContainer";
import { ChargingLocationsOverviewContainer } from "./integrations/charging-locations/overview/ChargingLocationsOverviewContainer";
import { UpdateChargingLocationContainer } from "./integrations/charging-locations/update/UpdateChargingLocationContainer";
import { DisconnectVendorContainer } from "./integrations/disconnect-vendor/DisconnectVendorContainer";
import { IntegrationsContainer } from "./integrations/IntegrationsContainer";
import { SmartChargingConfigurationContainer } from "./integrations/vehicle-overview/smart-charging/SmartChargingConfigurationContainer";
import { VehicleOverviewContainer } from "./integrations/vehicle-overview/VehicleOverviewContainer";

export const Routes = (): JSX.Element => {
  return (
    <Switch>
      <Route
        path={RouteMap.integrations.add}
        component={AddIntegrationContainer}
        exact
      />

      <Route
        path={RouteMap.integrations.vehicles.vehicle.basePath}
        component={VehicleOverviewContainer}
        exact
      />

      <Route
        path={RouteMap.integrations.vehicles.vehicle.smartCharging.basePath}
        component={SmartChargingConfigurationContainer}
        exact
      />

      <Route
        path={RouteMap.integrations.chargingLocations.add}
        component={AddChargingLocationContainer}
        exact
      />

      <Route
        path={RouteMap.integrations.vehicles.vehicle.chargingHistory.basePath}
        component={ChargingHistoryContainer}
        exact
      />

      <Route
        path={RouteMap.integrations.chargingLocations.basePath}
        component={ChargingLocationsOverviewContainer}
        exact
      />

      <Route
        path={RouteMap.integrations.chargingLocations.update.basePath}
        component={UpdateChargingLocationContainer}
        exact
      />

      <Route
        path={RouteMap.integrations.disconnect}
        component={DisconnectVendorContainer}
        exact
      />

      <Route path="/" component={IntegrationsContainer} exact />
    </Switch>
  );
};

export const RouteMap = {
  base: "/",
  integrations: {
    add: "/integrations/add",
    chargingLocations: {
      add: "/charging-locations/add",
      basePath: "/charging-locations",
      delete: {
        basePath: "/charging-locations/:chargingLocationId/delete",
        createPathForLocationId: (chargingLocationId: string): string =>
          `/charging-locations/${chargingLocationId}/delete`,
      },
      update: {
        basePath: "/charging-locations/:chargingLocationId/update",
        createPathForLocationId: (chargingLocationId: string): string =>
          `/charging-locations/${chargingLocationId}/update`,
      },
    },

    disconnect: "/integrations/disconnect",

    vehicles: {
      vehicle: {
        basePath: "/vehicles/:vehicleId",
        chargingHistory: {
          basePath: "/vehicles/:vehicleId/charging-history",
          createPathForVehicleId: (vehicleId: string): string =>
            `/vehicles/${vehicleId}/charging-history`,
        },

        createPathForVehicleId: (vehicleId: string): string =>
          `/vehicles/${vehicleId}`,
        smartCharging: {
          basePath: "/vehicles/:vehicleId/configure-smart-charging",
          createPathForVehicleId: (vehicleId: string): string =>
            `/vehicles/${vehicleId}/configure-smart-charging`,
        },
      },
    },
  },
};
