import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util/propsToTextColor";
import { IconProps } from "../Icon";

export const CollapseIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ textColor: color, theme })
    : theme.colors.text[500];

  return (
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.95437 1.49571L4.01901 0.56034L0.0317384 4.54761L0.967105 5.48297L4.01605 2.43403L7.03343 5.4514L7.96879 4.51603L4.95142 1.49866L4.95437 1.49571Z"
        fill={fill}
      />
    </>
  );
};
