import styled from "styled-components";
import {
  propsToTextColor,
  TextColor,
  VariantProps,
} from "../util/propsToTextColor";

type TypographyTag = "h1" | "h2" | "h3" | "p";
export enum TypographyVariant {
  HEADER = "HEADER",
  SUB_HEADER = "SUB_HEADER",
  PARAGRAPH = "PARAGRAPH",
  DETAIL = "DETAIL",
  LABEL = "LABEL",
  NUMBER = "NUMBER",
  NUMBER_SMALL = "NUMBER_SMALL",
  BUTTON = "BUTTON",
}

export enum TextAlign {
  CENTER = "center",
}

interface Props {
  textAlign?: TextAlign;
  centerVertically?: boolean;
  emphasized?: boolean;
  tag?: TypographyTag;
  text: string;
  textColor?: TextColor;
  variant?: TypographyVariant;
  style?: React.CSSProperties;
}

export const Typography = ({
  centerVertically = false,
  emphasized,
  tag = "p",
  style: customStyle,
  text,
  textAlign,
  textColor = TextColor.PRIMARY,
  variant = TypographyVariant.PARAGRAPH,
}: Props): JSX.Element => {
  const DynamicComponent = getStyleWithTagForVariant({ tag, variant });

  // eslint-disable-next-line no-magic-numbers
  const fontWeight = emphasized ? "bold" : 400;

  const style = centerVertically
    ? { alignItems: "center", display: "flex", ...customStyle }
    : customStyle;

  return (
    <DynamicComponent
      textColor={textColor}
      style={{ fontWeight, textAlign, ...style }}
    >
      {text}
    </DynamicComponent>
  );
};

const getStyleWithTagForVariant = ({
  tag,
  variant,
}: {
  tag: TypographyTag;
  variant: TypographyVariant;
}) => {
  switch (variant) {
    case TypographyVariant.HEADER:
      return HeaderStyle.withComponent(tag);

    case TypographyVariant.SUB_HEADER:
      return SubHeaderStyle.withComponent(tag);

    case TypographyVariant.DETAIL:
      return DetailStyle.withComponent(tag);

    case TypographyVariant.LABEL:
      return LabelStyle.withComponent(tag);

    case TypographyVariant.NUMBER:
      return NumberStyle.withComponent(tag);

    case TypographyVariant.NUMBER_SMALL:
      return NumberSmallStyle.withComponent(tag);

    case TypographyVariant.BUTTON:
      return ButtonStyle.withComponent(tag);

    default:
      return ParagraphStyle.withComponent(tag);
  }
};

const HeaderStyle = styled.div<VariantProps>`
  color: ${(props) => propsToTextColor(props)};
  font-size: 1.5rem;
  line-height: 1.5rem;
  letter-spacing: 0.08rem;
  font-variant-caps: all-small-caps;
`;

const SubHeaderStyle = styled.div<VariantProps>`
  color: ${(props) => propsToTextColor(props)};
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-variant-caps: all-small-caps;
  font-weight: 600;
  letter-spacing: 0.08rem;
`;

const DetailStyle = styled.div<VariantProps>`
  color: ${(props) => propsToTextColor(props)};
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.04rem;
  font-variant-caps: all-small-caps;
`;

const ParagraphStyle = styled.div<VariantProps>`
  color: ${(props) => propsToTextColor(props)};
  font-size: 1rem;
  line-height: 1rem;
  font-weight: normal;

  letter-spacing: 0.04rem;
`;

const LabelStyle = styled.div<VariantProps>`
  color: ${(props) => propsToTextColor(props)};
  letter-spacing: 0.04rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-variant-caps: all-small-caps;
  font-weight: 600;
`;

const NumberStyle = styled.div<VariantProps>`
  color: ${(props) => propsToTextColor(props)};
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 900;
`;

const NumberSmallStyle = styled.div<VariantProps>`
  color: ${(props) => propsToTextColor(props)};
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 900;
`;

const ButtonStyle = styled.div<VariantProps>`
  color: ${(props) => propsToTextColor(props)};
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: 0.12rem;
  font-variant-caps: all-small-caps;
`;
