import { DateTime } from "luxon";
import { Spacing } from "../../components/constraints/Spacing";
import { PositionIcon } from "../../components/icon/positionIcons/PositionIcon";
import { Row } from "../../components/Row";
import {
  TextAlign,
  Typography,
  TypographyVariant,
} from "../../components/Typography";
import { getLatLngAddress } from "../../util";
import { useIntegrations } from "./integration-provider/IntegrationProvider";
import { ConfiguredIntegrationVendorVehicle } from "./integration-provider/useEnodeIntegration";

interface CurrentLocationProps {
  vehicle: ConfiguredIntegrationVendorVehicle;
}

export const CurrentLocation = ({
  vehicle,
}: CurrentLocationProps): JSX.Element => {
  const [hasPosition, currentLocation] = locationToCurrentLocation(vehicle);
  return (
    <Row
      childSpacing={Spacing.NONE}
      style={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <PositionIcon hasPosition={hasPosition} size={12} />

      <Typography
        style={{
          fontSize: 12,
          marginLeft: "2px",
          opacity: 0.75,
        }}
        centerVertically
        variant={TypographyVariant.PARAGRAPH}
        text={currentLocation}
        textAlign={TextAlign.CENTER}
      />
    </Row>
  );
};

const locationToCurrentLocation = (
  vehicle: ConfiguredIntegrationVendorVehicle
): [boolean, string] => {
  const hasLatitude =
    vehicle.location.type === "LOCATION_AVAILABLE" &&
    !!vehicle.location.latitude;

  const { chargingLocations } = useIntegrations();

  const [timeSinceLastSeen, lastSeenMoreThanFourHoursAgo] =
    vehicleLocationStatus(vehicle);

  if (!vehicle.isReachable && !!timeSinceLastSeen) {
    return [true, `Sist sett ${timeSinceLastSeen}`];
  }
  if (vehicle.isReachable && hasLatitude && lastSeenMoreThanFourHoursAgo) {
    return [false, "Lokasjonsdata utilgjengelig"];
  }
  if (
    vehicle.isReachable &&
    vehicle.location.type === "LOCATION_AVAILABLE" &&
    !!vehicle.location.latitude &&
    !vehicle.chargingLocationId
  ) {
    const locationName = getLatLngAddress(vehicle.location);
    return [true, `${locationName}`];
  }
  if (vehicle.chargingLocationId) {
    const chargingLocation = chargingLocations.find(
      (location) => location.id === vehicle.chargingLocationId
    );

    const chargingLocationName =
      chargingLocation?.name ?? chargingLocation
        ? getLatLngAddress(chargingLocation)
        : "Din ladelokasjon";

    return [true, `${chargingLocationName}`];
  }

  // TODO handle other location types ("GPS_NEVER_ACTIVATED" and "GPS_DISABLED")
  return [false, "Lokasjonsdata utilgjengelig"];
};

const vehicleLocationStatus = (
  vehicle: ConfiguredIntegrationVendorVehicle
): [string, boolean] => {
  // eslint-disable-next-line no-magic-numbers
  const minutesInFourHours = 4 * 60;
  // eslint-disable-next-line no-magic-numbers
  const millisInOneMinute = 60 * 1000;

  let timeSinceLastSeen = "";
  let lastSeenMoreThanFourHoursAgo = true;

  if (vehicle.location.type === "LOCATION_AVAILABLE") {
    const lastSeen = DateTime.fromISO(vehicle.location.lastUpdateIsoString);
    const minutesSinceLastSeen = parseInt(
      (
        (DateTime.now().valueOf() - lastSeen.valueOf()) /
        millisInOneMinute
      ).toFixed(0)
    );

    timeSinceLastSeen =
      lastSeen.setLocale("no").toRelative() ?? lastSeen.toLocaleString();

    lastSeenMoreThanFourHoursAgo = minutesInFourHours <= minutesSinceLastSeen;
  }
  return [timeSinceLastSeen, lastSeenMoreThanFourHoursAgo];
};
