import { CSSProperties } from "react";
import { NoPositionIcon } from "./icons/NoPositionIcon";
import { HasPositionIcon } from "./icons/HasPositionIcon";

interface Props {
  hasPosition: boolean;
  size?: number;
  style?: CSSProperties;
  onClick?: () => void;
}

export const PositionIcon = ({
  style,
  size: customSize,
  hasPosition,
  onClick,
}: Props): JSX.Element => {
  const size = customSize ?? "8";
  return (
    <svg
      style={style}
      width={size}
      height={size}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      {hasPosition ? <HasPositionIcon /> : <NoPositionIcon />}
    </svg>
  );
};
