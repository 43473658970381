import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../../../../components/Button";
import { Col } from "../../../../components/Col";
import { Spacing } from "../../../../components/constraints/Spacing";
import { ErrorText } from "../../../../components/ErrorText";
import { LinkButton } from "../../../../components/LinkButton";
import { IconType, TimeInput } from "../../../../components";
import { Typography } from "../../../../components/Typography";
import { useUpdateSmartChargingPolicyMutation } from "../../../../generated/graphql";
import {
  convertLocalTimeStringToUtcTimeString,
  convertUtcTimeStringToLocalTimeString,
} from "../../../../util";
import { RouteMap } from "../../../Routes";
import { useIntegrations } from "../../integration-provider/IntegrationProvider";
import { IntegrationVendorComponent } from "../../vendors/IntegrationVendorComponent";
import { CardButton } from "../../CardButton";

interface SmartChargingConfigurationContainerProps {
  vehicleId: string;
}

export const SmartChargingConfigurationContainer = (): JSX.Element => {
  const history = useHistory();

  const { chargingLocations, reload } = useIntegrations();

  const onAddClicked = () => {
    if (chargingLocations.length > 0) {
      history.push(RouteMap.integrations.chargingLocations.basePath);
    } else {
      history.push(RouteMap.integrations.chargingLocations.add);
    }
  };

  const { vehicles } = useIntegrations();
  const { vehicleId } = useParams<SmartChargingConfigurationContainerProps>();

  const currentVehicle = vehicles.find((vehicle) => vehicle.id === vehicleId);

  const existingChargeDeadline =
    currentVehicle?.smartChargingPolicy?.deadlineInUtcTime;
  const [timeString, setTimeString] = useState(
    existingChargeDeadline
      ? convertUtcTimeStringToLocalTimeString(existingChargeDeadline)
      : "08:00"
  );

  const [updateSmartChargingPolicyMutation] =
    useUpdateSmartChargingPolicyMutation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const submitSmartCharging = async () => {
    try {
      setErrorMessage(undefined);
      setLoading(true);
      const utcAdjustedTimeString =
        convertLocalTimeStringToUtcTimeString(timeString);

      await updateSmartChargingPolicyMutation({
        variables: {
          input: {
            deadlineInUtcTime: utcAdjustedTimeString,
            isEnabled: true,
            vehicleId,
          },
        },
      });

      await reload();

      history.push(
        RouteMap.integrations.vehicles.vehicle.createPathForVehicleId(vehicleId)
      );
    } catch (err) {
      setLoading(false);
      setErrorMessage("Kunne ikke oppdatere");
    }
  };

  if (!currentVehicle) {
    return <Typography text={`Kobling med id ${vehicleId} ikke funnet.`} />;
  }

  return (
    <Col childSpacing={Spacing.MEDIUM}>
      <Col childSpacing={Spacing.TINY}>
        <Typography text="Smartoppsett for" emphasized />

        <IntegrationVendorComponent vehicle={currentVehicle} logoSize="24px" />
      </Col>

      <Col childSpacing={Spacing.SMALL}>
        <Typography
          text={
            "Smartlading er kun mulig på dine angitte lokasjoner (for å ikke påvirke lading på offentlige ladepunkter)."
          }
        />

        <CardButton
          text={locationCountToButtonString(chargingLocations.length)}
          iconLeft={{
            type: chargingLocations.length > 0 ? IconType.EDIT : IconType.PLUS,
          }}
          iconRight={{ type: IconType.CONTINUE }}
          onClick={onAddClicked}
        />
      </Col>

      <Col childSpacing={Spacing.SMALL}>
        <Typography text="Klokkeslettet du velger, avgjør når batteriet senest skal være fulladet." />

        <TimeInput value={timeString} onValueChanged={setTimeString} />
      </Col>

      <Col childSpacing={Spacing.SMALL}>
        <Button
          loading={loading}
          disabled={chargingLocations.length < 1}
          text="Fullfør oppsett"
          onClick={submitSmartCharging}
        />
        <ErrorText text={errorMessage} />

        <LinkButton text="Avbryt" onClick={history.goBack} />
      </Col>
    </Col>
  );
};

const locationCountToButtonString = (locationCount: number): string => {
  switch (locationCount) {
    case 0:
      return "Angi lokasjoner";
    default:
      return "Tilpass lokasjoner";
  }
};
