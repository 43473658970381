import { DateTime } from "luxon";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  ReferenceArea,
  ReferenceLine,
} from "recharts";
import { useTheme } from "styled-components";
import {
  DetailedPlan,
  getHourString,
  rewriteHoursMinutesToDecimalSystem,
} from "../../../../util";
import {
  ChargingArea,
  DeadlineLabel,
  makeSmartChargingPlanGraphData,
  PauseLabel,
} from "./smart-charging-plan";

interface SmartChargingPlanGraphProps {
  planDetails: DetailedPlan;
  deadline?: string;
}

export const SmartChargingPlanGraph = ({
  planDetails,
  deadline,
}: SmartChargingPlanGraphProps): JSX.Element => {
  const theme = useTheme();

  const now = DateTime.now();
  const noon = { hour: 12, millisecond: 0, minute: 0, second: 0 };

  const startAt =
    // eslint-disable-next-line no-magic-numbers
    parseInt(getHourString(deadline || planDetails.finishAt)) < 12
      ? // eslint-disable-next-line no-magic-numbers
        now.get("hour") > 12
        ? now.set(noon)
        : now.set(noon).minus({ days: 1 })
      : now.startOf("day");

  const data = makeSmartChargingPlanGraphData(startAt.get("hour"));

  const chargeTimeStart = rewriteHoursMinutesToDecimalSystem(
    startAt.get("hour") === 0 ? "midnight" : "noon",
    planDetails.resumeAt
  );
  const chargeTimeEnd = rewriteHoursMinutesToDecimalSystem(
    startAt.get("hour") === 0 ? "midnight" : "noon",
    planDetails.finishAt
  );
  const pauseAt = rewriteHoursMinutesToDecimalSystem(
    startAt.get("hour") === 0 ? "midnight" : "noon",
    planDetails.pauseAt
  );
  const deadlineAt = rewriteHoursMinutesToDecimalSystem(
    startAt.get("hour") === 0 ? "midnight" : "noon",
    deadline
  );

  return (
    <ResponsiveContainer width="100%" height={100}>
      <LineChart data={data} margin={{ bottom: 0, left: 0, right: 14, top: 0 }}>
        <XAxis
          xAxisId="main"
          dataKey="hour"
          tickLine={false}
          axisLine={false}
          interval={3}
          tickFormatter={(tick) =>
            parseInt(tick) === startAt.get("hour") ? "" : tick
          }
          fontSize={10}
        />

        <XAxis
          dataKey="count"
          type="number"
          xAxisId="time"
          allowDecimals
          hide
        />

        <CartesianGrid horizontal={false} strokeDasharray="2" />

        <YAxis
          yAxisId="left"
          axisLine={false}
          tick={false}
          width={14}
          y1={0}
          y2={1}
        />

        <ReferenceArea
          yAxisId="left"
          xAxisId="time"
          ifOverflow="extendDomain"
          shape={<ChargingArea />}
          fill={theme.colors.success[500]}
          x1={chargeTimeStart}
          x2={chargeTimeEnd}
          y1={0}
          y2={1}
        />

        <ReferenceLine
          xAxisId="time"
          yAxisId="left"
          stroke={theme.colors.warning[500]}
          label={PauseLabel}
          x={pauseAt}
        />

        <ReferenceLine
          xAxisId="time"
          yAxisId="left"
          stroke={theme.colors.success[500]}
          label={DeadlineLabel}
          x={deadlineAt}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
