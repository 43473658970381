import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import { useInterval } from "react-use";
import { BackHeader, Icon, IconType } from "../../../components";
import { Card } from "../../../components/Card";
import { Col } from "../../../components/Col";
import { Spacing } from "../../../components/constraints/Spacing";
import { Loader } from "../../../components/Loader";
import { HorizontalAlignment } from "../../../components/mixins/HorizontalAlignment";
import { Typography, TypographyVariant } from "../../../components/Typography";
import { chargeStateToBatteryChargeState } from "../../../util";
import { RouteMap } from "../../Routes";
import { BatteryProgressBar } from "../BatteryProgressBar";
import { ChargingStatusCard } from "../ChargingStatusCard";
import { CurrentLocation } from "../CurrentLocation";
import { useIntegrations } from "../integration-provider/IntegrationProvider";
import { IntegrationVendorComponent } from "../vendors/IntegrationVendorComponent";
import { SmartChargingPolicySection } from "./SmartChargingPolicySection";

export interface VehicleOverviewContainerProps {
  vehicleId: string;
}

export const VehicleOverviewContainer = (): JSX.Element => {
  const history = useHistory();
  const { vehicleId } = useParams<VehicleOverviewContainerProps>();
  const { vehicles, reload: reloadVehicles } = useIntegrations();

  const currentVehicle = vehicles.find((vehicle) => vehicle.id === vehicleId);

  if (!currentVehicle) {
    return <Typography text={`Kobling med id ${vehicleId} ikke funnet.`} />;
  }

  const goToConfigureSmartCharging = () => {
    history.push(
      RouteMap.integrations.vehicles.vehicle.smartCharging.createPathForVehicleId(
        vehicleId
      )
    );
  };

  const goToLocationsOverview = () => {
    history.push(RouteMap.integrations.chargingLocations.basePath);
  };

  const goToVehicleOverview = () => {
    history.push(RouteMap.base);
  };

  const goToVehicleChargingHistory = () => {
    history.push(
      RouteMap.integrations.vehicles.vehicle.chargingHistory.createPathForVehicleId(
        vehicleId
      )
    );
  };

  useInterval(reloadVehicles, 10 * 1000);

  const [smartChargingStatus, setSmartChargingStatus] = useState("");

  return (
    <PullToRefresh
      onRefresh={reloadVehicles}
      pullingContent=""
      refreshingContent={<Loader />}
    >
      <Col childSpacing={Spacing.MEDIUM}>
        <BackHeader
          goBack={() => goToVehicleOverview()}
          header={
            <IntegrationVendorComponent
              noBackground
              fullWidth
              logoSize="24px"
              vehicle={currentVehicle}
            />
          }
        />

        <Col
          horizontalAlignChildren={HorizontalAlignment.CENTER}
          childSpacing={Spacing.NONE}
        >
          <BatteryInfoText
            text={`${currentVehicle.chargeState.batteryLevel} %`}
            variant={TypographyVariant.NUMBER}
          />

          <BatteryInfoText
            text={`${currentVehicle.chargeState.range} km`}
            variant={TypographyVariant.SUB_HEADER}
            faded
          />
        </Col>

        <Col
          horizontalAlignChildren={HorizontalAlignment.CENTER}
          childSpacing={Spacing.TINY}
          style={{ marginTop: 8 }}
        >
          <BatteryProgressBar
            progress={currentVehicle.chargeState.batteryLevel}
            batteryChargeState={chargeStateToBatteryChargeState(
              currentVehicle.chargeState
            )}
            height="24px"
            batteryPadding="6px"
            gradient
            width="calc((100% + 5rem)/2)"
          />

          <ChargingStatusCard
            vehicle={currentVehicle}
            width="calc((100% + 5rem)/2)"
          />

          <CurrentLocation vehicle={currentVehicle} />
        </Col>

        <SmartChargingPolicySection
          smartChargingPolicy={currentVehicle.smartChargingPolicy}
          smartChargingStatus={smartChargingStatus}
          goToLocationsOverview={goToLocationsOverview}
          goToConfigureSmartCharging={goToConfigureSmartCharging}
          reloadVehicles={reloadVehicles}
          chargeState={currentVehicle.chargeState}
          deadline={currentVehicle.smartChargingPolicy?.deadlineInUtcTime}
          isSmartChargingCapable={currentVehicle.isSmartChargingCapable}
          vehicleId={vehicleId}
          setSmartChargingStatus={setSmartChargingStatus}
        />

        <Card
          padding={Spacing.SMALL}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={goToVehicleChargingHistory}
        >
          <Typography
            text="Ladehistorikk"
            variant={TypographyVariant.SUB_HEADER}
            emphasized
          />
          <Icon type={IconType.CONTINUE} size={16} centerVertically />
        </Card>
      </Col>
    </PullToRefresh>
  );
};

interface BatteryInfoTextProps {
  faded?: boolean;
  variant?: TypographyVariant;
  text: string;
}

const BatteryInfoText = ({ faded, text, variant }: BatteryInfoTextProps) => {
  return (
    <Typography
      text={text}
      variant={variant}
      emphasized
      // eslint-disable-next-line no-magic-numbers
      style={{ opacity: faded ? 0.75 : 1 }}
    />
  );
};
