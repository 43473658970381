import { useTheme } from "styled-components";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PauseLabel = (props: any) => {
  const { viewBox } = props;
  const theme = useTheme();

  return (
    <svg
      width={30}
      height={30}
      // eslint-disable-next-line no-magic-numbers
      x={viewBox.x - 10}
      y={viewBox.y}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H9C10.6569 0 12 1.34315 12 3V9C12 10.6569 10.6569 12 9 12H3C1.34315 12 0 10.6569 0 9V3Z"
        fill={theme.colors.warning[500]}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.75 9.00073V3.00073H5.25V9.00073H3.75Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.75 9.00073V3.00073H8.25V9.00073H6.75Z"
        fill="white"
      />
    </svg>
  );
};
