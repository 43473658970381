import { useTheme } from "styled-components";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DeadlineLabel = (props: any) => {
  const { viewBox } = props;
  const theme = useTheme();

  return (
    <svg
      width={30}
      height={30}
      // eslint-disable-next-line no-magic-numbers
      x={viewBox.x - 10}
      y={viewBox.y}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H9C10.6569 0 12 1.34315 12 3V9C12 10.6569 10.6569 12 9 12H3C1.34315 12 0 10.6569 0 9V3Z"
        fill={theme.colors.success[500]}
      />
      <line
        x1="3.53033"
        y1="6.03217"
        x2="5.53033"
        y2="8.03217"
        stroke="white"
        stroke-width="1.5"
      />
      <line
        x1="4.46967"
        y1="8.03217"
        x2="8.46967"
        y2="4.03217"
        stroke="white"
        stroke-width="1.5"
      />
    </svg>
  );
};
