import { useState } from "react";
import { useHistory } from "react-router";
import { useAddChargingLocationMutation } from "../../../../generated/graphql";
import { useIntegrations } from "../../integration-provider/IntegrationProvider";
import {
  ChargingLocationForm,
  ChargingLocationFormInput,
} from "../update/ChargingLocationForm";

export const AddChargingLocationContainer = (): JSX.Element => {
  const history = useHistory();
  const { reload } = useIntegrations();

  const [addChargingLocationMutation] = useAddChargingLocationMutation();
  const [isAddingChargingLocation, setIsAddingChargingLocation] =
    useState(false);
  const [addChargingLocationError, setAddChargingLocationError] = useState<
    string | undefined
  >(undefined);

  const handleFormInputSubmission = async (
    input: ChargingLocationFormInput
  ): Promise<void> => {
    try {
      setIsAddingChargingLocation(true);
      setAddChargingLocationError(undefined);

      const result = await addChargingLocationMutation({
        variables: {
          input: {
            latitude: input.latLng.latitude,
            longitude: input.latLng.longitude,
            name: input.name,
          },
        },
      });

      if (
        result.data?.addChargingLocation.__typename ===
        "AddChargingLocationSuccessPayload"
      ) {
        await reload(); // Hack until cache stuff is figured out

        return history.goBack();
      }

      setIsAddingChargingLocation(false);

      setAddChargingLocationError(
        "Lignende ladelokasjon eksisterer allerede. Endre navn eller sted."
      );
    } catch (err) {
      console.error(err);
      setIsAddingChargingLocation(false);
      setAddChargingLocationError("Noe gikk galt");
    }
  };

  return (
    <ChargingLocationForm
      onCancelClicked={history.goBack}
      onFormInputSubmitted={handleFormInputSubmission}
      submitChargingLocationErrorMessage={addChargingLocationError}
      isSubmittingChargingLocation={isAddingChargingLocation}
      formTitle="Ny lokasjon"
    />
  );
};

export interface PlaceResult {
  label: string;
  value: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    place_id: string;
  };
}
