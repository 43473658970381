import { useHistory } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { Col } from "../../components/Col";
import { Spacing } from "../../components/constraints/Spacing";
import { VerticalAlignment } from "../../components/mixins/VerticalAlignment";
import { Row } from "../../components/Row";
import { Typography, TypographyVariant } from "../../components/Typography";
import { Button as ButtonComponent } from "../../components/Button";
import { RouteMap } from "../Routes";
import { ConfiguredEnodeVendorVehicleListEntry } from "./ConfiguredEnodeVendorVehicleListEntry";
import { useIntegrations } from "./integration-provider/IntegrationProvider";
import { IconType } from "../../components";
import { TextColor } from "../../util/propsToTextColor";
import { useEffect, useRef } from "react";

export const IntegrationsContainer = (): JSX.Element => {
  const history = useHistory();
  const { vehicles, reload } = useIntegrations();
  const hasReloadedRef = useRef(false);

  useEffect(() => {
    if (!hasReloadedRef.current) {
      reload();
      hasReloadedRef.current = true;
    }
  }, []);

  const onAddClicked = () => {
    history.push(RouteMap.integrations.add);
  };

  const goToVehicle = (vehicleId: string) => {
    history.push(
      RouteMap.integrations.vehicles.vehicle.createPathForVehicleId(vehicleId)
    );
  };

  const goToDisconnectVendor = () => {
    history.push(RouteMap.integrations.disconnect);
  };

  return (
    <Col childSpacing={Spacing.MEDIUM}>
      <Row
        style={{
          flexGrow: 1,
          justifyContent: "space-between",
        }}
        verticalAlignChildren={VerticalAlignment.CENTER}
      >
        <Typography variant={TypographyVariant.HEADER} text="Smartkoblinger" />

        <AddButton onClick={onAddClicked} />
      </Row>

      {vehicles.map((vehicle) => (
        <ConfiguredEnodeVendorVehicleListEntry
          vehicle={vehicle}
          onClick={() => goToVehicle(vehicle.id)}
          key={vehicle.id}
        />
      ))}

      {vehicles.length > 0 && (
        <ButtonComponent
          iconLeft={{ type: IconType.DELETE }}
          text="Fjern en tilkobling"
          textColor={TextColor.DANGER}
          iconRight={{ type: IconType.CONNECTION }}
          onClick={() => goToDisconnectVendor()}
        />
      )}
    </Col>
  );
};

interface AddButtonProps {
  onClick: () => void;
}

export const AddButton = ({ onClick }: AddButtonProps): JSX.Element => {
  const theme = useTheme();
  const { 500: fill } = theme.colors.modalBackground;

  return (
    <Button onClick={onClick}>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.25003 0.00012207H4.74999V3.25003H7.99991V4.74999H4.74999V7.99991H3.25003V4.74999H0.00012207L0.000122136 3.25003H3.25003V0.00012207Z"
          fill={fill}
        />
      </svg>
    </Button>
  );
};

const Button = styled.button`
  border: 0;
  width: 32px;
  height: 16px;
  background: ${(props) => props.theme.colors.text[500]};
  box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.25),
    1px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
