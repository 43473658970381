import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util/propsToTextColor";
import { IconProps } from "../Icon";

export const PlusIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ textColor: color, theme })
    : theme.colors.text[500];

  return (
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.5 0H9.5V6.5H16V9.5H9.5V16H6.5V9.5H0V6.5H6.5V0Z"
        fill={fill}
      />
    </>
  );
};
