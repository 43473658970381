import { useEffect, useState } from "react";
import { useInterval } from "react-use";
import { Card } from "../../components/Card";
import { Spacing } from "../../components/constraints/Spacing";
import { ChargeStateIcon } from "../../components/icon/chargeStateIcons/ChargeStateIcon";
import { ChargeStateIconType } from "../../components/icon/chargeStateIcons/ChargeStateIconType";
import { VerticalAlignment } from "../../components/mixins/VerticalAlignment";
import { Row } from "../../components/Row";
import {
  TextAlign,
  Typography,
  TypographyVariant,
} from "../../components/Typography";
import {
  SmartChargingConsideration,
  SmartChargingPlanningStatusType,
  useVehicleSmartChargingStatusQuery,
} from "../../generated/graphql";
import { chargeStateToColor } from "../../util";
import { ConfiguredIntegrationVendorVehicle } from "./integration-provider/useEnodeIntegration";

interface ChargingStatusCardProps {
  vehicle: ConfiguredIntegrationVendorVehicle;
  width?: string;
}

export const ChargingStatusCard = ({
  vehicle,
  width,
}: ChargingStatusCardProps): JSX.Element => {
  const { data, refetch } = useVehicleSmartChargingStatusQuery({
    variables: { input: { vehicleId: vehicle.id } },
  });

  useInterval(refetch, 10 * 1000);

  const [chargePlan, setChargePlan] = useState<
    SmartChargingPlanningStatusType | undefined
  >();
  const [consideration, setConsideration] = useState<
    SmartChargingConsideration | undefined
  >();

  useEffect(() => {
    if (
      data?.vehicleSmartChargingStatus.__typename ===
      "PlannedSmartChargingStatus"
    ) {
      setChargePlan(data.vehicleSmartChargingStatus.planningStatus);
      setConsideration(undefined);
    }
    if (
      data?.vehicleSmartChargingStatus.__typename ===
      "ConsideringSmartChargingStatus"
    ) {
      setConsideration(data.vehicleSmartChargingStatus.consideration);
      setChargePlan(undefined);
    }
  }, [data]);

  const { icon, status } = chargeStatus(
    vehicle,
    data?.vehicleSmartChargingStatus.externalStart ?? false,
    chargePlan,
    consideration
  );
  return (
    <Card
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        minHeight: "24px",
        paddingLeft: "8px",
        paddingRight: "8px",
        width: width,
      }}
    >
      <Row
        childSpacing={Spacing.TINY}
        verticalAlignChildren={VerticalAlignment.CENTER}
      >
        <ChargeStateIcon type={icon} size={12} />

        <Typography
          textColor={chargeStateToColor(vehicle.chargeState)}
          variant={TypographyVariant.SUB_HEADER}
          style={{
            fontSize: "14px",
            translate: "0 -1px",
          }}
          textAlign={TextAlign.CENTER}
          text={status}
          emphasized
        />
      </Row>
    </Card>
  );
};

const chargeStatus = (
  vehicle: ConfiguredIntegrationVendorVehicle,
  externalStart: boolean,
  chargePlan?: SmartChargingPlanningStatusType,
  consideration?: SmartChargingConsideration
): ChargeStatusType => {
  if (!vehicle.isReachable) {
    return {
      icon: ChargeStateIconType.NOT_REACHABLE,
      status: "Ikke tilgjengelig",
    };
  }
  if (!vehicle.chargeState.isPluggedIn) {
    return {
      icon: ChargeStateIconType.NOT_PLUGGED_IN,
      status: "Ikke tilkoblet lader",
    };
  }
  if (!vehicle.chargeState.isCharging && vehicle.chargeState.isPluggedIn) {
    return {
      icon: ChargeStateIconType.PLUGGED_IN_NOT_CHARGING,
      status: "Tilkoblet, lader ikke",
    };
  }
  if (vehicle.chargeState.isFullyCharged) {
    return {
      icon: ChargeStateIconType.FULLY_CHARGED,
      status: "Fulladet",
    };
  }
  if (chargePlan === "PLAN_EXECUTING_STOPPING") {
    return {
      icon: ChargeStateIconType.EXECUTING_ACTION,
      status: "Pauser lading",
    };
  }
  if (
    chargePlan === "PLAN_EXECUTING_STOPPED" ||
    chargePlan === "PLAN_EXECUTING_STOPPED_AWAITING_PRICES"
  ) {
    return {
      icon: ChargeStateIconType.PAUSED,
      status: "Lading pauset",
    };
  }
  if (
    chargePlan === "PLAN_EXECUTING_STARTING" ||
    (!vehicle.chargeState.isCharging && externalStart)
  ) {
    return {
      icon: ChargeStateIconType.EXECUTING_ACTION,
      status: "Fortsetter lading",
    };
  }
  if (consideration?.isCharging) {
    return {
      icon: ChargeStateIconType.CHARGING_UNTIL,
      status: "Lader nå til 20 %",
    };
  }
  if (vehicle.chargeState.isCharging) {
    return {
      icon: ChargeStateIconType.CHARGING,
      status: "Lader nå",
    };
  }

  // TODO: track how often this happens
  return {
    icon: ChargeStateIconType.NOT_REACHABLE,
    status: "Ukjent ladestatus",
  };
};

interface ChargeStatusType {
  icon: ChargeStateIconType;
  status: ChargeStatus;
}

type ChargeStatus =
  | "Ukjent ladestatus"
  | "Lader nå"
  | "Lader nå til 20 %"
  | "Fortsetter lading"
  | "Lading pauset"
  | "Pauser lading"
  | "Fulladet"
  | "Tilkoblet, lader ikke"
  | "Ikke tilkoblet lader"
  | "Ikke tilgjengelig";
