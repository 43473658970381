import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util/propsToTextColor";
import { IconProps } from "../Icon";

export const RightArrowIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ textColor: color, theme })
    : theme.colors.text[500];

  return (
    <>
      <path
        d="M8.62501 2.716L17.909 12L8.625 21.284L8.62501 2.716Z"
        fill={fill}
        stroke={fill}
        stroke-width="2.25"
      />
    </>
  );
};
