import { useTheme } from "styled-components";

export const BmwLogo = (): JSX.Element => {
  const { 500: color } = useTheme().colors.text;

  return (
    <>
      <path
        d="M24 8C15.162 8 8 15.11 8 23.876c0 8.767 7.162 15.876 16 15.876 8.838 0 16-7.109 16-15.876C40.004 15.11 32.838 8 24 8zm14.961 15.876c0 8.197-6.698 14.846-14.961 14.846S9.039 32.072 9.039 23.876C9.039 15.68 15.737 9.03 24 9.03s14.961 6.65 14.961 14.846z"
        fill={color}
      />
      <path
        d="M31.759 17.138c.217.23.53.606.707.832l3.241-2.043a25.5 25.5 0 00-.61-.756l-2.052 1.349-.141.119.106-.15.907-1.795-.642-.637-1.808.897-.15.102.12-.137 1.357-2.034a19.873 19.873 0 00-.76-.61l-2.057 3.214c.261.2.606.491.823.69l1.936-.995.124-.088-.093.124-1.008 1.918zm-7.357-4.067l.884-1.967.058-.173-.013.177.093 2.63c.309.032.632.071.946.124l-.146-3.895a19.209 19.209 0 00-1.322-.101l-.871 2.148-.031.16-.031-.16-.871-2.148c-.442.017-.88.053-1.322.101l-.146 3.895c.319-.053.637-.092.946-.123l.093-2.63-.018-.182.058.172.884 1.968c.005.004.81.004.81.004zm-7.158 3.104c.513-.535.8-1.154.292-1.742-.278-.319-.747-.39-1.136-.235l-.04.014.014-.036c.057-.15.092-.636-.323-.972a.97.97 0 00-.716-.2c-.482.05-.85.377-1.857 1.49a22.97 22.97 0 00-1.013 1.212l2.763 2.604c.92-1.008 1.291-1.38 2.016-2.135zm-3.607-.637a17.704 17.704 0 011.415-1.534c.084-.075.176-.155.287-.181.181-.049.371.08.411.265.044.186-.075.362-.208.508-.296.332-1.375 1.45-1.375 1.45l-.53-.508zm1.083 1.017s1.052-1.105 1.397-1.455c.137-.141.226-.225.318-.27.12-.057.252-.07.372.01.115.08.172.212.141.344-.035.16-.163.31-.27.425l-1.392 1.48-.566-.534z"
        fill={color}
      />
      <path
        d="M33.493 23.876h-9.492V14.46c5.248 0 9.492 4.213 9.492 9.417zm-9.492 0v9.417c-5.244 0-9.488-4.213-9.488-9.417h9.488z"
        fill={color}
      />
      <path
        d="M24.001 14.46v9.416h-9.488c0-5.204 4.245-9.417 9.488-9.417zm9.492 9.416c0 5.204-4.244 9.417-9.488 9.417v-9.417h9.488z"
        fill="url(#prefix__paint0_linear_1133:3464)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_1133:3464"
          x1={24.003}
          y1={14.459}
          x2={24.003}
          y2={33.293}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} stopOpacity={0.4} />
          <stop offset={1} stopColor={color} stopOpacity={0.2} />
        </linearGradient>
      </defs>
    </>
  );
};
