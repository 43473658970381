import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util/propsToTextColor";
import { IconProps } from "../Icon";

export const ContinueIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ textColor: color, theme })
    : theme.colors.text[500];

  return (
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 2.80396L16 8.00011L7 13.1963V8.90011H0V7.10011H7V2.80396Z"
        fill={fill}
      />
    </>
  );
};
