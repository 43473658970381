import styled from "styled-components";

interface Props {
  label: string;
  id: string;
  onChange: (newValue: string) => void;
  value: string;
}

export const TextInput = ({
  label,
  id,
  value,
  onChange,
}: Props): JSX.Element => {
  return (
    <InputContainer>
      <Label htmlFor={id}>{label}</Label>
      <Input
        id={id}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  justify-self: stretch;
  display: flex;
  flex-direction: column;
  height: 4rem;
`;

const Label = styled.label`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: ${(props) => props.theme.colors.text[500]};
  font-variant: small-caps;
  text-transform: lowercase;

  margin-left: 4px;
  margin-bottom: 4px;
`;

const Input = styled.input`
  background: ${(props) => props.theme.colors.background[500]};
  border: 0;
  height: 2.5rem;
  color: ${(props) => props.theme.colors.text[500]};
  padding: 8px 12px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  border-radius: 0.25rem;
`;
