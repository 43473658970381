/* eslint-disable no-magic-numbers */
import styled, { CSSProperties } from "styled-components";
import {
  propsToTextColor,
  TextColor,
  VariantProps,
} from "../util/propsToTextColor";
import { Icon, IconType } from "./icon";
import { Loader } from "./Loader";

export interface ButtonIconProps {
  type: IconType;
  color?: TextColor;
}

interface Props {
  loading?: boolean;
  text: string;
  textColor?: TextColor;
  style?: CSSProperties;
  disabled?: boolean;
  fullWidth?: boolean;
  iconLeft?: ButtonIconProps;
  iconRight?: ButtonIconProps;
  onClick: () => void;
}

export const Button = ({
  loading,
  text,
  textColor = TextColor.PRIMARY,
  disabled: formInvalid = false,
  fullWidth = false,
  iconLeft,
  iconRight,
  style,
  onClick,
}: Props): JSX.Element => {
  const disabled = formInvalid || loading;

  return (
    <StyledButton
      textColor={textColor}
      style={style}
      disabled={disabled}
      fullWidth={fullWidth}
      hasIcon={!!iconLeft || !!iconRight}
      onClick={onClick}
    >
      {iconLeft && <Icon type={iconLeft.type} color={iconLeft.color} />}
      {loading ? <Loader size="SMALL" /> : text}
      {iconRight && <Icon type={iconRight.type} color={iconRight.color} />}
    </StyledButton>
  );
};

interface StyledButtonProps extends VariantProps {
  hasIcon: boolean;
  fullWidth: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  border: 0;
  background: ${(props) => `linear-gradient(
    0deg,
    ${props.theme.colors.text[200]} 0%,
    ${props.theme.colors.text[100]} 100%
  )`};
  box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.15),
    1px 1px 1px rgba(0, 0, 0, 0.15);
  border-radius: 32px;
  padding: 16px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  letter-spacing: 0.12em;
  text-transform: uppercase;

  color: ${(props) => propsToTextColor(props)};

  ${(props) =>
    props.hasIcon &&
    `
      justify-content: space-between;
      display: flex;
    `}

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.5;
    `}

  ${(props) =>
    props.fullWidth &&
    `
      width: 100%;
    `}
`;
