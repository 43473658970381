import { useTheme } from "styled-components";

export const PluggedInNotChargingIcon = (): JSX.Element => {
  const theme = useTheme();
  const { 500: fill } = theme.colors.text;

  return (
    <>
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.43258 0.932576C2.12731 0.350468 3.02273 0 4 0C4.16431 0 4.32631 0.00990697 4.48542 0.0291562L3.16667 2.66667L1.43258 0.932576ZM0.932576 1.43258C0.350468 2.12731 0 3.02273 0 4C0 6.03981 1.52684 7.723 3.5 7.96905V5H2L2.83333 3.33333L0.932576 1.43258ZM4.83333 5.33333L3.51458 7.97084C3.6737 7.99009 3.83569 8 4 8C4.97727 8 5.87269 7.64953 6.56742 7.06742L4.83333 5.33333ZM7.06742 6.56742C7.64953 5.87269 8 4.97727 8 4C8 1.96019 6.47316 0.276996 4.5 0.0309469V3H6L5.16667 4.66667L7.06742 6.56742Z"
        fill={fill}
      />
      <path d="M0.5 0.5L7.5 7.5" stroke={fill} stroke-linecap="round" />
    </>
  );
};
