import { DateTime } from "luxon";
import { ChargeState } from "../app/integrations/integration-provider/useEnodeIntegration";
import { VehicleSmartChargingStatusQuery } from "../generated/graphql";
import { considerationToSmartChargingStatus } from "./considerationToSmartChargingStatus";
import { planningToSmartChargingStatus } from "./planningToSmartChargingStatus";

interface UpdateSmartChargingStatusProps {
  state: VehicleSmartChargingStatusQuery;
  deadline?: string;
  chargeState: ChargeState;
}

export const updateSmartChargingStatus = ({
  state,
  deadline,
  chargeState,
}: UpdateSmartChargingStatusProps) => {
  switch (state.vehicleSmartChargingStatus.__typename) {
    case "PlannedSmartChargingStatus": {
      const plannedStatus = planningToSmartChargingStatus(
        state.vehicleSmartChargingStatus.planningStatus,
        state.vehicleSmartChargingStatus.plan,
        deadline
      );
      return plannedStatus;
    }
    case "ConsideringSmartChargingStatus": {
      const smartChargingStatus = considerationToSmartChargingStatus({
        consideration: state.vehicleSmartChargingStatus.consideration,
        isFullyCharged: chargeState.isFullyCharged,
      });

      if (smartChargingStatus.type === "SMART_CHARGING_ACTIVE") {
        const endAt = DateTime.now().plus({
          minutes: chargeState.chargeTimeRemaining,
        });

        const estimatedFinishAtHoursMinutes = endAt.toLocaleString(
          DateTime.TIME_24_SIMPLE
        );
        const estimatedFinishedAtText = `Klar til ${estimatedFinishAtHoursMinutes}`;

        return estimatedFinishedAtText;
      } else {
        return smartChargingStatus.reason;
      }
    }
    case "FullyChargedSmartChargingStatus":
      return "Kjøretøyet er fulladet.";
    case "DisabledSmartChargingStatus":
      return "Smartladingen er deaktivert, og kjøretøyet vil lade med en gang laderen kobles til, uavhengig av strømpris. Klikk ovenfor dersom du vil reaktivere.";
  }
};
