import { useEffect, useState } from "react";
import { Col } from "../../components/Col";
import { Spacing } from "../../components/constraints/Spacing";
import { Loader } from "../../components/Loader";
import { HorizontalAlignment } from "../../components/mixins/HorizontalAlignment";
import { Row } from "../../components/Row";
import {
  TextAlign,
  Typography,
  TypographyVariant,
} from "../../components/Typography";
import { useIntegrationAuthorizationUrlLazyQuery } from "../../generated/graphql";
import { IntegrationCard } from "./add/IntegrationCard";

export const NoIntegrationsYet = (): JSX.Element => {
  return (
    <Col>
      <Col
        childSpacing={Spacing.MEDIUM}
        horizontalAlignChildren={HorizontalAlignment.CENTER}
      >
        <Typography text="Smartkoblinger" variant={TypographyVariant.HEADER} />

        <Typography
          textAlign={TextAlign.CENTER}
          text="Ingen smarte koblinger aktivert. Velg din leverandør nedenfor for å koble opp."
        />

        <IntegrationVendorOverviewGrid />
      </Col>
    </Col>
  );
};

export const IntegrationVendorOverviewGrid = (): JSX.Element => {
  const [selectedVendor, setSelectedVendor] = useState<IntegrationVendor>();

  const [
    integrationAuthorizationUrlQuery,
    { data: authorizationUrlData, loading },
  ] = useIntegrationAuthorizationUrlLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [isRedirectingToAuthorizationUrl, setIsRedirectingToAuthorizationUrl] =
    useState(false);

  // This retrieves a new authorization URL whenever selected vendor is updated
  useEffect(() => {
    if (!selectedVendor) {
      return;
    }
    integrationAuthorizationUrlQuery({
      variables: {
        input: { vendor: selectedVendor },
      },
    });
  }, [selectedVendor]);

  useEffect(() => {
    if (authorizationUrlData) {
      setIsRedirectingToAuthorizationUrl(true);
      window.location.href =
        authorizationUrlData.integrationAuthorizationUrl.authorizationUrl;
    }
  }, [authorizationUrlData]);

  if (loading) {
    return <Loader />;
  }

  if (isRedirectingToAuthorizationUrl) {
    return (
      <Col horizontalAlignChildren={HorizontalAlignment.CENTER}>
        <Row childSpacing={Spacing.TINY}>
          <Typography text={`Laster integrasjon`} />
          <Loader size="SMALL" />
        </Row>
      </Col>
    );
  }

  return (
    <Row
      style={{
        alignSelf: "stretch",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {supportedBrands.map((vendor) => (
        <IntegrationCard
          style={{ marginLeft: Spacing.TINY, marginTop: Spacing.TINY }}
          key={vendor}
          integrationVendor={vendor}
          onClick={() => setSelectedVendor(vendor)}
        />
      ))}
    </Row>
  );
};

const supportedBrands: EnodeVehicleBrand[] = [
  "AUDI",
  "BMW",
  "CUPRA",
  // "FORD",
  "JAGUAR",
  "MINI",
  "PORSCHE",
  "RENAULT",
  "SEAT",
  "SKODA",
  "TESLA",
  "VOLKSWAGEN",
  "VOLVO",
];

export type EnodeVehicleBrand =
  | "AUDI"
  | "BMW"
  | "CUPRA"
  // | "FORD"
  | "JAGUAR"
  | "MINI"
  | "PORSCHE"
  | "RENAULT"
  | "SEAT"
  | "SKODA"
  | "TESLA"
  | "VOLKSWAGEN"
  | "VOLVO";
export type IntegrationVendor = EnodeVehicleBrand;
