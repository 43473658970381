import { useTheme } from "styled-components";

export const PausedIcon = (): JSX.Element => {
  const theme = useTheme();
  const { 500: fill } = theme.colors.text;

  return (
    <>
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.25 7C1.83579 7 1.5 6.66421 1.5 6.25V1.75C1.5 1.33579 1.83579 1 2.25 1C2.66421 1 3 1.33579 3 1.75V6.25C3 6.66421 2.66421 7 2.25 7Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.75 7C5.33579 7 5 6.66421 5 6.25V1.75C5 1.33579 5.33579 1 5.75 1C6.16421 1 6.5 1.33579 6.5 1.75V6.25C6.5 6.66421 6.16421 7 5.75 7Z"
        fill={fill}
      />
    </>
  );
};
