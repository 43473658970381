import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Col } from "../../../../components/Col";
import { Spacing } from "../../../../components/constraints/Spacing";
import { Typography } from "../../../../components/Typography";
import { useUpdateChargingLocationMutation } from "../../../../generated/graphql";
import { useIntegrations } from "../../integration-provider/IntegrationProvider";
import {
  ChargingLocationForm,
  ChargingLocationFormInput,
} from "./ChargingLocationForm";

interface EditChargingLocationContainerParams {
  chargingLocationId: string;
}

export const UpdateChargingLocationContainer = (): JSX.Element => {
  const { chargingLocationId } =
    useParams<EditChargingLocationContainerParams>();

  const history = useHistory();

  const { chargingLocations, reload } = useIntegrations();
  const currentChargingLocation = chargingLocations.find(
    (location) => chargingLocationId === location.id
  );

  const updateAndGoBack = async () => {
    await reload();
    history.goBack();
  };

  const [updateChargingLocation, { loading, error }] =
    useUpdateChargingLocationMutation();
  const handleFormSubmission = async (
    input: ChargingLocationFormInput
  ): Promise<void> => {
    const result = await updateChargingLocation({
      variables: {
        input: {
          id: chargingLocationId,
          latitude: input.latLng.latitude,
          longitude: input.latLng.longitude,
          name: input.name,
        },
      },
    });
    if (result.data) {
      history.goBack();
    }
  };

  if (!currentChargingLocation) {
    return (
      <Typography text={`Ladepunkt med id ${chargingLocationId} ikke funnet`} />
    );
  }

  return (
    <Col style={{ flexGrow: 1 }} padding={{ bottom: Spacing.LARGE }}>
      <ChargingLocationForm
        style={{ flexGrow: 1 }}
        formTitle="Tilpass ladepunkt"
        initialChargingLocation={currentChargingLocation}
        isSubmittingChargingLocation={loading}
        chargingLocationId={chargingLocationId}
        onDeletionComplete={updateAndGoBack}
        onCancelClicked={history.goBack}
        onFormInputSubmitted={handleFormSubmission}
        submitChargingLocationErrorMessage={
          error &&
          "Kunne ikke oppdatere ladepunkt. Prøv igjen eller kontakt oss om problemet vedvarer"
        }
      />
    </Col>
  );
};
