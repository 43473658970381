import { Card } from "./Card";
import { Spacing } from "./constraints/Spacing";
import { Row } from "./Row";
import { Typography, TypographyVariant } from "./Typography";
import { Icon, IconType } from ".";

interface Props {
  onClick: () => void;
  text: string;
}

export const NavigationButton = ({ onClick, text }: Props): JSX.Element => {
  return (
    <Card
      padding={Spacing.SMALL}
      onClick={onClick}
      style={{ alignSelf: "stretch" }}
    >
      <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
        <Typography
          emphasized
          variant={TypographyVariant.SUB_HEADER}
          text={text}
        />
        <Icon type={IconType.OVERVIEW} />
      </Row>
    </Card>
  );
};
