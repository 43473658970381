import { useEffect, useState } from "react";
import {
  ConfiguredIntegrationVendorVehicle as GqlConfiguredIntegrationVendorVehicle,
  ConfiguredIntegrationVendorVehicleLocationState as GqlConfiguredIntegrationVendorVehicleLocationState,
  useMyIntegrationsQuery,
} from "../../../generated/graphql";
import { IntegrationVendor } from "../NoIntegrationsYet";
import { IntegrationVendorVehicleLocationState } from "../types/IntegrationVendorVehicleLocationState";

export const useEnodeIntegration = (): EnodeIntegration => {
  const [state, setState] = useState<AsyncEnodeIntegrationState>({
    loading: true,
  });

  const queryResult = useMyIntegrationsQuery();

  useEffect(() => {
    if (queryResult.data) {
      const vehicles: ConfiguredIntegrationVendorVehicle[] = [];
      queryResult.data.me.linkedIntegrationVendors?.forEach((vendor) => {
        if (vendor.__typename === "ConfiguredIntegrationVendorInfo") {
          return vendor.devices.forEach((device) => {
            if (device.__typename === "ConfiguredIntegrationVendorVehicle") {
              vehicles.push(
                gqlConfiguredIntegrationVendorVehicleToIntegrationVendorVehicle(
                  device
                )
              );
            }
          });
        }
      });

      const chargingLocations: ChargingLocation[] =
        queryResult.data.me.chargingLocations || [];

      setState({
        loading: false,
        value: {
          chargingLocations,
          key: EnodeIntegrationStateKey.AUTHENTICATED,
          vehicles,
        },
      });
    }

    if (queryResult.error) {
      const hasFailedPreconditionError = queryResult.error.graphQLErrors.find(
        (error) => error.extensions?.code === "FAILED_PRECONDITION"
      );
      if (hasFailedPreconditionError) {
        setState({
          loading: false,
          value: {
            key: EnodeIntegrationStateKey.NOT_AUTHENTICATED,
          },
        });
      } else {
        setState({
          loading: false,
          value: {
            key: EnodeIntegrationStateKey.UNKNOWN_ERROR,
          },
        });
      }
    }

    if (queryResult.loading) {
      setState({ loading: true });
    }
  }, [queryResult.data, queryResult.loading, queryResult.error]);

  return {
    refetch: async () => {
      try {
        await queryResult.refetch();
      } catch (err) {
        // errors are handled declaratively, need to swallow it here to avoid runtime error while awaiting refetch
      }
    },
    state,
  };
};

export interface EnodeIntegration {
  state: AsyncEnodeIntegrationState;
  refetch: () => Promise<void>;
}

export type AsyncEnodeIntegrationState = AsyncState<EnodeIntegrationState>;

interface AuthenticatedWithEnodeState {
  key: EnodeIntegrationStateKey.AUTHENTICATED;
  chargingLocations: ChargingLocation[];
  vehicles: ConfiguredIntegrationVendorVehicle[];
}

interface NotAuthenticatedWithEnodeState {
  key: EnodeIntegrationStateKey.NOT_AUTHENTICATED;
}

interface UnknownErrorEnodeState {
  key: EnodeIntegrationStateKey.UNKNOWN_ERROR;
}

type EnodeIntegrationState =
  | AuthenticatedWithEnodeState
  | NotAuthenticatedWithEnodeState
  | UnknownErrorEnodeState;

export enum EnodeIntegrationStateKey {
  AUTHENTICATED = "AUTHENTICATED",
  NOT_AUTHENTICATED = "NOT_AUTHENTICATED",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export interface SmartChargingPolicy {
  isEnabled: boolean;
  deadlineInUtcTime: string;
}

export interface ConfiguredIntegrationVendorVehicle {
  id: string;
  vendor: IntegrationVendor;
  model: string;
  yearOfProduction: number;
  isReachable: boolean;
  isSmartChargingCapable: boolean;
  chargingLocationId?: string;

  chargeState: ChargeState;
  location: IntegrationVendorVehicleLocationState;

  smartChargingPolicy?: SmartChargingPolicy;
}

export interface ChargeState {
  isFullyCharged: boolean;
  batteryLevel: number;
  isCharging: boolean;
  isPluggedIn: boolean;
  range: number;
  batteryCapacity: number;
  chargeRate: number;
  chargeTimeRemaining: number;
}

export interface ChargingLocation {
  name: string;
  latitude: number;
  longitude: number;
  id: string;
}

const gqlConfiguredIntegrationVendorVehicleToIntegrationVendorVehicle = (
  gqlVehicle: GqlConfiguredIntegrationVendorVehicle
): ConfiguredIntegrationVendorVehicle => {
  return {
    ...gqlVehicle,
    chargingLocationId: gqlVehicle.chargingLocationId ?? undefined,
    location:
      gqlIntegrationVendorVehicleLocationStateToIntegrationVendorVehicleLocationState(
        gqlVehicle.location
      ),
    smartChargingPolicy: gqlVehicle.smartChargingPolicy ?? undefined,
  };
};

export type AsyncState<T> =
  | {
      loading: boolean;
      error?: undefined;
      value?: undefined;
    }
  | {
      loading: false;
      error: Error;
      value?: undefined;
    }
  | {
      loading: false;
      error?: undefined;
      value: T;
    };

const gqlIntegrationVendorVehicleLocationStateToIntegrationVendorVehicleLocationState =
  (
    gqlState: GqlConfiguredIntegrationVendorVehicleLocationState
  ): IntegrationVendorVehicleLocationState => {
    if (
      gqlState.__typename ===
      "GpsDisabledConfiguredIntegrationVendorVehicleLocationState"
    ) {
      return { type: "GPS_DISABLED" };
    }

    if (
      gqlState.__typename ===
      "LocationAvailableConfiguredIntegrationVendorVehicleLocationState"
    ) {
      return {
        lastUpdateIsoString: gqlState.lastUpdatedAt,
        latitude: gqlState.latitude,
        longitude: gqlState.longitude,
        type: "LOCATION_AVAILABLE",
      };
    }

    return { type: "GPS_NEVER_ACTIVATED" };
  };
