import { Card } from "../../../components/Card";
import { Col } from "../../../components/Col";
import { Spacing } from "../../../components/constraints/Spacing";
import { Loader } from "../../../components/Loader";
import { HorizontalAlignment } from "../../../components/mixins/HorizontalAlignment";
import { Typography } from "../../../components/Typography";
import { TextColor } from "../../../util/propsToTextColor";

interface SummaryCardProps {
  title: string;
  loading: boolean;
  content: JSX.Element;
  color?: TextColor;
}

export const SummaryCard = ({
  title,
  loading,
  color,
  content,
}: SummaryCardProps) => {
  return (
    <Card
      padding={{ horizontal: Spacing.TINY, vertical: Spacing.SMALL }}
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Col>
        <Typography
          text={title}
          textColor={color}
          style={{
            fontSize: "10px",
          }}
        />
        {loading ? (
          <Col horizontalAlignChildren={HorizontalAlignment.CENTER}>
            <Loader size="SMALL" />
          </Col>
        ) : (
          content
        )}
      </Col>
    </Card>
  );
};
