import { useTheme } from "styled-components";

export const HasPositionIcon = (): JSX.Element => {
  const theme = useTheme();
  const { 500: fill } = theme.colors.text;

  return (
    <>
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.00507 1.00507C-0.334933 2.34508 -0.335024 4.5176 1.0048 5.85771C1.00481 5.85773 1.00481 5.85775 1.0048 5.85777V5.85777C1.00478 5.85779 1.00478 5.85781 1.0048 5.85783L2.94594 7.79896C3.21396 8.06698 3.6485 8.06698 3.91652 7.79896L5.82294 5.89254C5.83468 5.88113 5.84637 5.86961 5.85799 5.85799C7.19808 4.51789 7.19808 2.34517 5.85799 1.00507C4.51789 -0.335024 2.34517 -0.335024 1.00507 1.00507ZM2.43025 2.30541C1.87573 2.85993 1.87573 3.75899 2.43025 4.31351C2.98477 4.86803 3.88383 4.86803 4.43835 4.31351C4.99287 3.75899 4.99287 2.85993 4.43835 2.30541C3.88383 1.75088 2.98477 1.75088 2.43025 2.30541Z"
        fill={fill}
      />
    </>
  );
};
